import options, { perPageOptions } from '@/constants/options';
import blockListSlice, { blackListTableSelector, perPageSelector } from '@/redux/slice/blockList.slice';
import { ChoiceList } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';

const useFilterBlacklist = () => {
  const dispatch = useDispatch();
  const blackListTable = useSelector(blackListTableSelector);
  const perPage = useSelector(perPageSelector);

  const filters = [
    {
      key: 'type',
      label: 'Type',
      filter: (
        <ChoiceList
          title="Type"
          titleHidden
          choices={[{ label: 'All', value: '' }, ...options.blockTypeOptions]}
          selected={[blackListTable.type || '']}
          onChange={(value) =>
            dispatch(
              blockListSlice.actions.handleBlackListTable({
                ...blackListTable,
                type: value[0],
                page: 1,
              }),
            )
          }
        />
      ),
      shortcut: true,
    },
    {
      key: 'criteria',
      label: 'Criteria',
      filter: (
        <ChoiceList
          title="Criteria"
          titleHidden
          choices={[{ label: 'All', value: '' }, ...options.criteriaFilters]}
          selected={[blackListTable.criteria]}
          onChange={(value) =>
            dispatch(
              blockListSlice.actions.handleBlackListTable({
                ...blackListTable,
                criteria: value[0],
                page: 1,
              }),
            )
          }
        />
      ),
      shortcut: true,
    },
    {
      key: 'perPage',
      label: 'Per page',
      filter: (
        <ChoiceList
          title="Per page"
          titleHidden
          choices={perPageOptions}
          selected={[perPage]}
          onChange={(value) => {
            dispatch(blockListSlice.actions.handlePerPage(value[0]));
          }}
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [
    {
      onRemove: () =>
        dispatch(
          blockListSlice.actions.handleBlackListTable({
            ...blackListTable,
            type: '',
            page: 1,
          }),
        ),
      key: 'type',
      label: `Type: ${options.blockTypeOptions.find((item) => item.value === blackListTable.type)?.label || 'All'}`,
    },
    {
      onRemove: () =>
        dispatch(
          blockListSlice.actions.handleBlackListTable({
            ...blackListTable,
            criteria: '',
            page: 1,
          }),
        ),
      key: 'criteria',
      label: `Criteria: ${options.criteriaFilters.find((option) => option.value === blackListTable.criteria)?.label || 'All'}`,
    },
    {
      onRemove: () => dispatch(blockListSlice.actions.handlePerPage('10')),
      key: 'perPage',
      label: 'Record per page: ' + perPage,
    },
  ];

  return {
    filters,
    appliedFilters,
  };
};

export default useFilterBlacklist;
