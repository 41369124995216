import blockify from '@/asset/images/blockify.png';
import logo from '@/asset/images/logo.jpg';
import lockIcon from '@/asset/images/lockIcon.png';
import illustration from '@/asset/images/Illustration.png';
import insurance from '@/asset/images/Insurance.png';
import publishSite from '@/asset/images/publishSite.png';
import review from '@/asset/images/review.png';
import review2 from '@/asset/images/review2.png';
import block_collection from '@/asset/images/block_collection.png';
import block_isp from '@/asset/images/block_isp.png';
import block_state from '@/asset/images/block_state.png';
import block_ip_range from '@/asset/images/block_ip_range.png';
import block_ip_ranges from '@/asset/images/ip_ranges.png';
import block_specific_page from '@/asset/images/specific_page.png';
import userAgent from '@/asset/images/user-agent.png';
import block_ip_address from '@/asset/images/block_ip_address.png';
import block_country from '@/asset/images/block_country.png';
import step3Image from '@/asset/images/step3Image.png';
import openTheme from '@/asset/images/openTheme.gif';
import synctrackBanner from '@/asset/images/synctrack_banner.png';
import disputeImg from '@/asset/images/dispute.png';
import dashboardImg from '@/asset/images/dashboardImg.png';
import helpImg from '@/asset/images/helpImg.png';
import referralLink from '@/asset/images/referral.png';
import gradient_template from './images/template/gradient.png';
import light_purple_template from './images/template/light-purple.png';
import pixel_template from './images/template/pixel.png';
import simple_template from './images/template/simple.png';
import default_template from './images/template/default.png';
import template3D_template from './images/template/template3D.png';
import promote from './images/promote.png';
export const images = {
  blockify,
  logo,
  lockIcon,
  illustration,
  insurance,
  publishSite,
  review,
  review2,
  block_collection,
  block_isp,
  block_state,
  block_ip_range,
  block_ip_address,
  block_country,
  block_specific_page,
  openTheme,
  step3Image,
  synctrackBanner,
  helpImg,
  dashboardImg,
  disputeImg,
  referralLink,
  gradient_template,
  light_purple_template,
  pixel_template,
  simple_template,
  template3D_template,
  default_template,
  block_ip_ranges,
  userAgent,
  promote
};
