import styled from 'styled-components';
interface Props {
  maxWidth?: boolean;
}
export const LayoutStyled = styled.div<Props>`
  .Polaris-Frame__Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .Polaris-Page {
      width: 100%;
      .Polaris-Page--divider {
        min-height: calc(100vh - 76px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 32px;
      }
    }
    .Polaris-Page > .Polaris-Box {
      margin-bottom: 1rem;
      position: sticky !important;
      top: 45px;
      z-index: 500;
      background-color: #f1f1f1;
      padding: 20px 4px;
      box-shadow: 0px 28px 20px -25px rgba(26, 26, 26, 0.28);
    }
    .Polaris-Page > .Polaris-Box ~ .Polaris-Box {
      position: relative;
    }
  }
  .nav-bar {
    background-color: white;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 511;
    height: 45px;
    width: 100%;
    padding-left: 1.5rem;
    display: flex;
    align-items: center;
    .Polaris-ButtonGroup__Item {
      margin: 0;
    }
  }
  .layout-footer {
    margin: 2rem 0;
    display: flex;
    justify-content: center;
  }
  @media only screen and (max-width: 1040px) {
    .Polaris-Page {
      min-width: 0;
      max-width: calc(100vw - 72px);
    }
  }
`;
