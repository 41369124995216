import { Banner, Button, Collapsible } from '@shopify/polaris';
import { memo } from 'react';
import RegularText from '../RegularText';

interface Props {
  title?: string;
  isVisible: boolean;
  children: React.ReactNode;
  maxHeight?: number;
}

const CriticalBanner = ({ ...props }: Props) => {
  return (
    <div
      style={{
        marginBottom: props.isVisible ? 16 : 0,
      }}
    >
      <Collapsible open={props.isVisible} id="basic-collapsible" transition={{ duration: '500ms' }} expandOnPrint>
        <Banner title={props.title} tone="critical">
          <RegularText>{props.children}</RegularText>
          <div className="mt-8">
            <Button
              onClick={() => {
                try {
                  $crisp.push(['do', 'chat:open']);
                } catch (error) {
                  console.log(error);
                }
              }}
            >
              Contact support
            </Button>
          </div>
        </Banner>
      </Collapsible>
    </div>
  );
};

export default memo(CriticalBanner);
