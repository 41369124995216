import { Button, ButtonProps, Card, IconSource } from '@shopify/polaris';
import { memo } from 'react';
import BoldText from '../BoldText';
import { CardStyled } from './styled';

interface Props {
  title: string;
  actions?: {
    content: string;
    buttonProps: ButtonProps;
    onAction?: () => void;
    icon?: React.ReactElement | IconSource;
  };
  secondaryActions?: {
    content: string;
    buttonProps: ButtonProps;
    onAction?: () => void;
  };
  children: any;
  display?: string;
}

const CustomCard = ({ ...props }: Props) => {
  return (
    <CardStyled style={{ display: props.display || 'block' }}>
      <Card padding={{ xl: '0' }}>
        <div className="custom-card-header">
          <BoldText>{props.title}</BoldText>
          <div className="custom-card-btn">
            <div style={{ marginRight: '16px' }}>
              {props.actions && (
                <Button icon={props.actions.icon} onClick={props.actions.onAction} {...props.actions.buttonProps}>
                  {' '}
                  {props.actions?.content}{' '}
                </Button>
              )}
            </div>
            {props.secondaryActions && (
              <Button onClick={props.secondaryActions.onAction} {...props.secondaryActions.buttonProps}>
                {' '}
                {props.secondaryActions?.content}{' '}
              </Button>
            )}
          </div>
        </div>
        <div className="custom-card-content">{props.children}</div>
      </Card>
    </CardStyled>
  );
};

export default memo(CustomCard);
