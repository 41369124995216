/* eslint-disable jsx-a11y/img-redundant-alt */
import { images } from '@/asset';
import { config } from '@/config';
import { apiCaller } from '@/redux/query';
import { Link, Text } from '@shopify/polaris';

const StepThree = () => {
    const { data } = apiCaller.useGetGeneralDetailQuery(config.shop);
    return (
        <div className="step-three">
            <Text as="h3" variant="heading2xl">
                Let’s see how Blockify works
            </Text>
            <div className='step-three-content'>
                <img src={images.step3Image} alt='img' />
                <p className='mt-16'>
                    Try a demo blocking test with your IP and your store to see how Blockify works.{' '}
                    <Link url={data?.settings.testBlockUrl} external removeUnderline>
                        Test now
                    </Link>
                </p>
            </div>
        </div>
    );
};

export default StepThree;
