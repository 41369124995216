import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import { Enum } from '@/constants';
import { dateToTimeStamp } from '@/helpers';
import { apiCaller } from '@/redux/query';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import { allAccessSelector } from '@/redux/slice/visitorAnalytics.slice';
import { Card, Icon, InlineGrid, Link, SkeletonDisplayText, Text } from '@shopify/polaris';
import {
  BugMajor,
  CircleAlertMajor,
  CircleDisabledMajor,
  CustomersMajor,
  MobileAcceptMajor,
  RiskMajor,
} from '@shopify/polaris-icons';
import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
const Analytics = () => {
  const dataSettings = useSelector(dataSettingsSelector);
  const allAccess = useSelector(allAccessSelector);
  const blockedIps = apiCaller.useFetchChartBlockQuery({
    endDate: dateToTimeStamp(allAccess.endDate),
    startDate: dateToTimeStamp(allAccess.startDate),
  });
  const visitors = apiCaller.useFetchChartAnalyticsQuery({
    endDate: dateToTimeStamp(allAccess.endDate),
    startDate: dateToTimeStamp(allAccess.startDate),
  });
  const dataChart = useMemo(() => {
    const result = [
      {
        title: 'Total visitors',
        value: visitors.data?.data.totalVisit,
        icon: CustomersMajor,
      },
      {
        title: 'Total blocked IP',
        value: blockedIps.data?.data.totalBlocked,
        icon: CircleDisabledMajor,
      },
      {
        title: 'Total blocked VPN & Proxy',
        value: blockedIps.data?.data.totalVpn,
        icon: BugMajor,
      },
      {
        title: 'Low risk IP',
        value: visitors.data?.data.totalSafeIp,
        icon: MobileAcceptMajor,
      },
      {
        title: 'High risk IP',
        value: visitors.data?.data.totalRiskIp,
        icon: RiskMajor,
      },
      {
        title: 'Dangerous IP',
        value: visitors.data?.data.totalDangerousIp,
        icon: CircleAlertMajor,
      },
    ];
    return result;
  }, [blockedIps.data, visitors.data]);
  return (
    <Card>
      <BoldText>Overview</BoldText>
      <div className="analytics-container mt-16">
        <InlineGrid columns={{ xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }} gap={'400'}>
          {dataChart.map((item, index) => {
            return (
              <Card key={index}>
                <div className="analytics-items-title">
                  <Icon source={item.icon} tone="base" />
                  <Text fontWeight="medium" as="h5" variant="headingSm">
                    {item.title}
                  </Text>
                </div>
                <div className="mt-8">
                  {blockedIps.isFetching || visitors.isFetching ? (
                    <SkeletonDisplayText />
                  ) : (
                    <div>
                      {dataSettings?.settings.user.plan === Enum.UserPlan.FREE ?
                        <div>
                          {item.title !== 'Total visitors' && item.title !== 'Total blocked IP' ?
                            <RegularText>
                              <Link
                                removeUnderline
                                onClick={() =>
                                  window.open(
                                    `https://www.wix.com/apps/upgrade/${process.env.REACT_APP_APP_ID}?appInstanceId=${dataSettings?.settings.instanceId}`
                                  )
                                }
                              >
                                Upgrade now
                              </Link>{' '}
                              to see full metrics</RegularText>
                            :
                            <Text as="h5" variant="heading2xl" fontWeight="regular">
                              {(item.value || 0) < 20000 ? item.value : `${item.value}+`}
                            </Text>
                          }
                        </div>
                        :
                        <Text as="h5" variant="heading2xl" fontWeight="regular">
                          {item.value}
                        </Text>
                      }
                    </div>
                  )}
                </div>
              </Card>
            );
          })}
        </InlineGrid>
      </div>
    </Card>
  );
};

export default memo(Analytics);
