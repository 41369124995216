import { Enum } from '@/constants';
import { ISettings } from '@/types/components';
import styled from 'styled-components';

interface TemplateStyledProps {
  focusId?: string;
  css: string;
  template: ISettings.CustomTemplate;
}

export const SettingsStyled = styled.div`
  .Polaris-Box--listReset {
    padding: 0;
    .Polaris-BlockStack {
      background-color: #ebebeb;
      padding: 0.5rem;
      border-radius: 0.5rem;
      .Polaris-OptionList-Option--select {
        background-color: white !important;
      }
    }
  }

  .Polaris-OptionList-Option__Icon {
    display: none;
  }
  .settings-title {
    display: flex;
    .Polaris-Icon {
      margin-right: 0.5rem;
    }
  }
  .mr-8 {
    margin-right: 8px;
  }
  .mb-8 {
    margin-bottom: 8px;
  }
  .pointer {
    cursor: pointer;
  }
  .setting-risk-score {
    margin-bottom: 0.5rem;
    .Polaris-Icon {
      margin: 0;
      margin-left: 0.25rem;
    }
  }
  .Polaris-Link--monochrome {
    color: #005bd3;
  }
`;
export const TemplateFullScreenStyled = styled.div<TemplateStyledProps>`
  #blockify---container {
    position: relative !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    #blockify---container__inner {
      min-height: calc(100vh - 2rem) !important;
      display: flex;
      justify-content: center;
      align-items: center;
      #blockify-logo-block-image img {
        max-width: 550px;
        height: auto;
        max-height: 300px;
      }
      #blockify-block-content {
        #blockify-block-superTitle {
          font-size: 10rem;
          line-height: 10rem;
        }
        #blockify-block-title {
          word-break: break-word;
          font-size: 1.25rem;
          line-height: 1.25rem;
          margin: 1rem 0;
        }
        #blockify-block-description {
          display: flex;
          word-break: break-word;
          flex-direction: column;
          font-size: 0.75rem;
        }
      }
    }
    &:after {
      background-image: ${(props) =>
    props.template.background.type === Number(Enum.BackgroundType.Image)
      ? `url(${props.template.background.value})`
      : `linear-gradient(to right,${props.template.background.colorFrom}, ${props.template.background.colorTo})`};
      background-color: ${(props) =>
    Number(props.template.background.type) === Number(Enum.BackgroundType.Color) ? props.template.background.value : 'white'};
    }
    .template-logo {
      width: 2.5em;
      height: 2.5em;
      margin: 0 0.25em;
    }
    ${(props) => props.css}
    #blockify-block-superTitle {
      color: ${(props) => props.template.superTitle.color} !important;
    }
    #blockify-block-title {
      color: ${(props) => props.template.title.color} !important;
    }
    #blockify-block-description {
      color: ${(props) => props.template.description.color} !important;
    }
  }

  label[for=${(props) => props.focusId}] {
    border: 1px dotted #005bd3;
  }
`;

export const CardContentProtectionStyled = styled.div`
  .Polaris-LegacyCard {
    border-radius: none;
    box-shadow: none;
  }
`;

export const CustomHeaderStyled = styled.div`
  .Polaris-Page {
    padding: 0px;
  }
  .Polaris-Header-Title__TitleMetadata {
    margin-top: 4px;
  }
`;
