import { CriteriaType } from '@/constants/enum';
import blockListSlice, { IErrorRule, errorRuleSelector, settingSelector } from '@/redux/slice/blockList.slice';
import { useDispatch, useSelector } from 'react-redux';

const useErrorRule = () => {
  const criteria = useSelector(settingSelector).criteria as CriteriaType;
  const dispatch = useDispatch();
  const errorRule = useSelector(errorRuleSelector);
  const errorSelected = errorRule.find((item) => item.criteria === criteria);
  const handleErrorRule = (error?: string | React.ReactElement, specificCriteria?: CriteriaType) => {
    let criteriaSelected = specificCriteria || criteria;
    const list: IErrorRule[] = [...errorRule];
    const index = list.findIndex((item) => item.criteria === criteriaSelected);
    if (index !== -1) {
      list[index] = {
        ...list[index],
        error,
      };
    } else {
      list.push({
        criteria: criteriaSelected,
        error,
      });
    }
    dispatch(blockListSlice.actions.handleErrorRule(list));
  };
  const getErrorRuleByCriteria = (cri: CriteriaType) => {
    return errorRule.find((item) => item.criteria === cri);
  };

  const removeErrorRule = (specificCriteria?: CriteriaType) => {
    let criteriaSelected = specificCriteria || criteria;
    const list: IErrorRule[] = [...errorRule];
    const index = list.findIndex((item) => item.criteria === criteriaSelected);
    if (index !== -1) {
      list.splice(index, 1);
      dispatch(blockListSlice.actions.handleErrorRule(list));
    }
  };

  return {
    listError: errorRule,
    errorSelected,
    handleErrorRule,
    getErrorRuleByCriteria,
    removeErrorRule,
  };
};

export default useErrorRule;
