import { Collapsible } from '@shopify/polaris';
import { CSSProperties, memo, useState } from 'react';
import { ColorPicker, useColor } from 'react-color-palette';
import 'react-color-palette/css';
import { ColorPickerStyled } from './styled';
interface Props {
  onChange: (value: string) => void;
  style?: CSSProperties;
  color: string;
}

const CustomColorPicker = (props: Props) => {
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [color, setColor] = useColor(props.color || '#fff');

  return (
    <ColorPickerStyled>
      <div className="pre-view" style={{ backgroundColor: color.hex, ...props.style }} onClick={() => setOpen(!open)} />
      <Collapsible
        open={open}
        id="basic-collapsible"
        transition={{ duration: '200ms', timingFunction: 'ease-in-out' }}
        expandOnPrint
      >
        <ColorPicker
          height={100}
          hideAlpha
          color={color}
          onChange={(color) => {
            setColor(color);
            props.onChange(color.hex);
          }}
        />
      </Collapsible>
    </ColorPickerStyled>
  );
};

export default memo(CustomColorPicker);
