import { Enum } from '@/constants';
import { getSomeDaysAgo } from '@/helpers';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface ListBlocked {
  search?: string;
  status?: string;
  risk?: string;
  page: number;
  type?: string;
  sortBy: string;
  sort: string;
  countriesCode?: Array<string>;
  providers?: Array<string>;
  locations?: Array<string>;
}

export interface BlockedDetail {
  startDate: Date;
  endDate: Date;
  page: number;
  perPage: string;
  sortBy: string;
  sort: string;
}
const now = new Date();
// Define the initial state using that type
const initialState: {
  blockHistoryTable: ListBlocked;
  blockedDetail: BlockedDetail;
  inputSearch: string;
} = {
  blockHistoryTable: {
    search: undefined,
    status: undefined,
    risk: undefined,
    type: undefined,
    page: 1,
    sort: Enum.SortType.DESC,
    sortBy: 'lastSeenOn',
  },
  blockedDetail: {
    page: 1,
    sort: Enum.SortType.DESC,
    sortBy: 'accessAt',
    perPage: '10',
    endDate: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59),
    startDate: getSomeDaysAgo(7),
  },

  inputSearch: '',
};

export const dashboardSlice = createSlice({
  name: 'blockHistory',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleBlockHistoryTable: (state, action: PayloadAction<ListBlocked>) => {
      state.blockHistoryTable = action.payload;
    },
    handleBlockDetail: (state, action: PayloadAction<BlockedDetail>) => {
      state.blockedDetail = action.payload;
    },
    handleInputSearch: (state, action: PayloadAction<string>) => {
      state.inputSearch = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const blockHistorySelector = createSelector(
  (state: RootState) => state.dashboard,
  (state) => state.blockHistoryTable,
);
export const blockedDetailSelector = createSelector(
  (state: RootState) => state.dashboard,
  (state) => state.blockedDetail,
);
export const inputSearchSelector = createSelector(
  (state: RootState) => state.dashboard,
  (state) => state.inputSearch,
);

export default dashboardSlice;
