import AutoCompleteWithTag from '@/components/autoCompleteWithTag';
import { Enum } from '@/constants';
import { perPageOptions } from '@/constants/options';
import { dateToTimeStamp, mergeArrays, truncateString } from '@/helpers';
import useCountry from '@/hooks/useCountry';
import { apiCaller } from '@/redux/query';
import dashboardSlice, { blockHistorySelector } from '@/redux/slice/dashboard.slice';
import visitorLogSlice, {
  allAccessSelector,
  perPageSelector,
  tabsTableSelector,
  visitorLogSelector,
} from '@/redux/slice/visitorAnalytics.slice';
import { ChoiceList, FilterInterface } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import options from '../../options';
import { config } from '@/config';
import TooltipUpdateHigherPlan from './TooltipUpdateHigherPlan';

const Filters = () => {
  const dispatch = useDispatch();
  const perPage = useSelector(perPageSelector);
  const allAccess = useSelector(allAccessSelector);
  const visitorLogTable = useSelector(visitorLogSelector);
  const handleCountry = useCountry();
  const tabSelected = useSelector(tabsTableSelector);
  const accountPlan = apiCaller.useGetGeneralDetailQuery(config.shop);
  const plan = accountPlan.data?.settings.user.plan;

  const dataVisitor = apiCaller.useFetchChartAnalyticsQuery(
    {
      endDate: dateToTimeStamp(allAccess.endDate),
      startDate: dateToTimeStamp(allAccess.startDate),
    },
    { skip: tabSelected === 2 },
  );
  const dataBlocked = apiCaller.useFetchChartBlockQuery(
    {
      endDate: dateToTimeStamp(allAccess.endDate),
      startDate: dateToTimeStamp(allAccess.startDate),
    },
    { skip: tabSelected === 1 },
  );
  const getProviders = apiCaller.useGetProvidersQuery(
    {
      endDate: dateToTimeStamp(allAccess.endDate),
      startDate: dateToTimeStamp(allAccess.startDate),
    },
    { skip: tabSelected !== 0 },
  );
  const getProvidersAllowed = apiCaller.useGetProvidersQuery(
    {
      endDate: dateToTimeStamp(allAccess.endDate),
      startDate: dateToTimeStamp(allAccess.startDate),
      typeList: Enum.TypeList.Allowed,
    },
    { skip: tabSelected !== 1 },
  );
  const getProvidersBlocked = apiCaller.useGetProvidersQuery(
    {
      endDate: dateToTimeStamp(allAccess.endDate),
      startDate: dateToTimeStamp(allAccess.startDate),
      typeList: Enum.TypeList.Blocked,
    },
    { skip: tabSelected !== 2 },
  );
  const getLocations = apiCaller.useGetLocationsQuery(
    {
      endDate: dateToTimeStamp(allAccess.endDate),
      startDate: dateToTimeStamp(allAccess.startDate),
    },
    { skip: tabSelected !== 0 },
  );
  const getLocationsAllowed = apiCaller.useGetLocationsQuery(
    {
      endDate: dateToTimeStamp(allAccess.endDate),
      startDate: dateToTimeStamp(allAccess.startDate),
      typeList: Enum.TypeList.Allowed,
    },
    { skip: tabSelected !== 1 },
  );
  const getLocationsBlocked = apiCaller.useGetLocationsQuery(
    {
      endDate: dateToTimeStamp(allAccess.endDate),
      startDate: dateToTimeStamp(allAccess.startDate),
      typeList: Enum.TypeList.Blocked,
    },
    { skip: tabSelected !== 2 },
  );
  const providerFilterAll = getProviders.data?.data?.map((item: string) => ({ label: item, value: item }));
  const providerFilterAllowed = getProvidersAllowed.data?.data?.map((item: string) => ({ label: item, value: item }));
  const providerFilterBlocked = getProvidersBlocked.data?.data?.map((item: string) => ({ label: item, value: item }));
  const locationFilterAll = getLocations.data?.data?.map((item) => ({ label: item.name, value: item.name }));
  const locationFilterAllowed = getLocationsAllowed.data?.data?.map((item) => ({ label: item.name, value: item.name }));
  const locationFilterBlocked = getLocationsBlocked.data?.data?.map((item) => ({ label: item.name, value: item.name }));
  const countryFilterVisitor = handleCountry.listCountry.filter((item) => {
    return dataVisitor?.data?.data.heatMaps.map((item) => item.countryCode).includes(item.value);
  });
  const countryFilterBlock = handleCountry.listCountry.filter((item) => {
    return dataBlocked?.data?.data.heatMaps.map((item) => item.countryCode).includes(item.value);
  });
  const allCountryFilter = mergeArrays(countryFilterVisitor, countryFilterBlock);

  const handleOnChangePerPage = (selected: string[]) => {
    dispatch(visitorLogSlice.actions.handlePerPage(selected[0]));
    dispatch(
      visitorLogSlice.actions.handleAllAccessTable({
        ...accessAllTable,
        page: 1,
      }),
    );
    dispatch(
      visitorLogSlice.actions.handleVisitorLogTable({
        ...visitorLogTable,
        page: 1,
      }),
    );
    dispatch(
      dashboardSlice.actions.handleBlockHistoryTable({
        ...blockHistoryTable,
        page: 1,
      }),
    );
  };

  // Access all
  const accessAllTable = useSelector(allAccessSelector);

  const filtersAllAccess: FilterInterface[] = [
    {
      key: 'perPage',
      label: 'Per page',
      filter: (
        <ChoiceList title="Per page" titleHidden choices={perPageOptions} selected={[perPage]} onChange={handleOnChangePerPage} />
      ),
      shortcut: true,
    },
    {
      key: 'country',
      label: 'Country',
      filter: (
        <AutoCompleteWithTag
          options={allCountryFilter}
          label={'Country'}
          selectedOptions={accessAllTable.countriesCode || []}
          setSelectedOptions={(selected: string[]) => {
            dispatch(
              visitorLogSlice.actions.handleAllAccessTable({
                ...accessAllTable,
                countriesCode: selected,
                page: 1,
              }),
            );
          }}
          allowMultiple={true}
        />
      ),
      shortcut: true,
    },
    {
      key: 'isp',
      label: 'ISP',
      suffix: plan === Enum.UserPlan.FREE ? <TooltipUpdateHigherPlan /> : undefined,
      disabled: plan === Enum.UserPlan.FREE,
      filter: (
        <AutoCompleteWithTag
          options={providerFilterAll || []}
          label={'ISP'}
          selectedOptions={accessAllTable.providers || []}
          setSelectedOptions={(selected: string[]) => {
            dispatch(
              visitorLogSlice.actions.handleAllAccessTable({
                ...accessAllTable,
                providers: selected,
                page: 1,
              }),
            );
          }}
          allowMultiple={true}
        />
      ),
      shortcut: true,
    },
    {
      key: 'location',
      label: 'Location',
      shortcut: true,
      filter: (
        <AutoCompleteWithTag
          options={locationFilterAll || []}
          label={'Location'}
          selectedOptions={accessAllTable.locations || []}
          setSelectedOptions={(selected: string[]) => {
            dispatch(
              visitorLogSlice.actions.handleAllAccessTable({
                ...accessAllTable,
                locations: selected,
                page: 1,
              }),
            );
          }}
          allowMultiple={true}
        />
      ),
    },
    {
      key: 'risk',
      label: 'Risk level',
      shortcut: true,
      suffix: plan === Enum.UserPlan.FREE ? <TooltipUpdateHigherPlan /> : undefined,
      disabled: plan === Enum.UserPlan.FREE,
      filter: (
        <ChoiceList
          title="Risk level"
          titleHidden
          choices={options.riskOptions}
          selected={[accessAllTable.risk || '']}
          onChange={(value) => {
            dispatch(
              visitorLogSlice.actions.handleAllAccessTable({
                ...accessAllTable,
                risk: value[0] || undefined,
                page: 1,
              }),
            );
          }}
        />
      ),
    },
  ];

  const appliedFiltersAllAccess = [
    {
      onRemove: () => {
        dispatch(visitorLogSlice.actions.handlePerPage('10'));
        dispatch(
          visitorLogSlice.actions.handleAllAccessTable({
            ...accessAllTable,
            page: 1,
          }),
        );
      },
      key: 'perPage',
      label: 'Record per page: ' + perPage,
      hidden: perPage === '10',
    },
    {
      onRemove: () =>
        dispatch(
          visitorLogSlice.actions.handleAllAccessTable({
            ...accessAllTable,
            countriesCode: [],
            page: 1,
          }),
        ),
      key: 'country',
      label: `Country: ${truncateString(
        allCountryFilter
          .filter((item: { value: string }) => accessAllTable.countriesCode?.includes(item.value))
          ?.map((item: { label: any }) => item.label)
          .toString(),
        100,
      )}`,
      hidden: (accessAllTable.countriesCode || '').length === 0,
    },
    {
      onRemove: () =>
        dispatch(
          visitorLogSlice.actions.handleAllAccessTable({
            ...accessAllTable,
            providers: undefined,
            page: 1,
          }),
        ),
      key: 'isp',
      label: `ISP: ${truncateString(
        (providerFilterAll || [])
          .filter((item) => accessAllTable.providers?.includes(item.value))
          ?.map((item) => item.label)
          .toString(),
        100,
      )}`,
      hidden: (accessAllTable.providers || '').length === 0,
    },
    {
      onRemove: () =>
        dispatch(
          visitorLogSlice.actions.handleAllAccessTable({
            ...accessAllTable,
            locations: [],
            page: 1,
          }),
        ),
      key: 'location',
      label: `Location: ${truncateString(
        (locationFilterAll || [])
          .filter((item) => accessAllTable.locations?.includes(item.value))
          ?.map((item) => item.label)
          .toString(),
        100,
      )}`,
      hidden: (accessAllTable.locations || '').length === 0,
    },
    {
      onRemove: () =>
        dispatch(
          visitorLogSlice.actions.handleAllAccessTable({
            ...accessAllTable,
            risk: undefined,
            page: 1,
          }),
        ),
      key: 'risk',
      label: `Risk level: ${options.riskOptions.find((item) => item.value === accessAllTable.risk)?.label}`,
      hidden: !accessAllTable.risk,
    },
  ];

  // Allowed
  const filtersVisitors: FilterInterface[] = [
    {
      key: 'perPage',
      label: 'Per page',
      filter: (
        <ChoiceList
          title="Per page"
          titleHidden
          choices={perPageOptions}
          selected={[perPage || '']}
          onChange={handleOnChangePerPage}
        />
      ),
      shortcut: true,
    },
    {
      key: 'status',
      label: 'Status',
      shortcut: true,
      filter: (
        <ChoiceList
          title="Status"
          titleHidden
          choices={options.statusOptions}
          selected={[visitorLogTable.status || '']}
          onChange={(value) => {
            dispatch(
              visitorLogSlice.actions.handleVisitorLogTable({
                ...visitorLogTable,
                status: value[0] || undefined,
                page: 1,
              }),
            );
          }}
        />
      ),
    },
    {
      key: 'risk',
      label: 'Risk level',
      shortcut: true,
      suffix: plan === Enum.UserPlan.FREE ? <TooltipUpdateHigherPlan /> : undefined,
      disabled: plan === Enum.UserPlan.FREE,
      filter: (
        <ChoiceList
          title="Risk level"
          titleHidden
          choices={options.riskOptions}
          selected={[visitorLogTable.risk || '']}
          onChange={(value) => {
            dispatch(
              visitorLogSlice.actions.handleVisitorLogTable({
                ...visitorLogTable,
                risk: value[0] || undefined,
                page: 1,
              }),
            );
          }}
        />
      ),
    },
    {
      key: 'countriesCode',
      label: 'Country',
      shortcut: true,
      filter: (
        <AutoCompleteWithTag
          options={countryFilterVisitor || []}
          label={'Country'}
          selectedOptions={visitorLogTable.countriesCode || []}
          setSelectedOptions={(selected: string[]) => {
            dispatch(
              visitorLogSlice.actions.handleVisitorLogTable({
                ...visitorLogTable,
                countriesCode: selected,
                page: 1,
              }),
            );
          }}
          allowMultiple={true}
        />
      ),
    },
    {
      key: 'isp',
      label: 'ISP',
      suffix: plan === Enum.UserPlan.FREE ? <TooltipUpdateHigherPlan /> : undefined,
      disabled: plan === Enum.UserPlan.FREE,
      shortcut: true,
      filter: (
        <AutoCompleteWithTag
          options={providerFilterAllowed || []}
          label={'ISP'}
          selectedOptions={visitorLogTable.providers || []}
          setSelectedOptions={(selected: string[]) => {
            dispatch(
              visitorLogSlice.actions.handleVisitorLogTable({
                ...visitorLogTable,
                providers: selected,
                page: 1,
              }),
            );
          }}
          allowMultiple={true}
        />
      ),
    },
    {
      key: 'location',
      label: 'Location',
      shortcut: true,
      filter: (
        <AutoCompleteWithTag
          options={locationFilterAllowed || []}
          label={'Location'}
          selectedOptions={visitorLogTable.locations || []}
          setSelectedOptions={(selected: string[]) => {
            dispatch(
              visitorLogSlice.actions.handleVisitorLogTable({
                ...visitorLogTable,
                locations: selected,
                page: 1,
              }),
            );
          }}
          allowMultiple={true}
        />
      ),
    },
  ];

  const appliedFiltersVisitors = [
    {
      onRemove: () => {
        dispatch(visitorLogSlice.actions.handlePerPage('10'));
        dispatch(
          visitorLogSlice.actions.handleVisitorLogTable({
            ...visitorLogTable,
            page: 1,
          }),
        );
      },

      key: 'perPage',
      label: 'Record per page: ' + perPage,
      hidden: perPage === '10',
    },
    {
      onRemove: () =>
        dispatch(
          visitorLogSlice.actions.handleVisitorLogTable({
            ...visitorLogTable,
            status: '',
            page: 1,
          }),
        ),
      key: 'status',
      label: `Status: ${options.statusOptions.find((item) => item.value === visitorLogTable.status)?.label || 'All'}`,
      hidden: !visitorLogTable.status,
    },
    {
      onRemove: () =>
        dispatch(
          visitorLogSlice.actions.handleVisitorLogTable({
            ...visitorLogTable,
            risk: undefined,
            page: 1,
          }),
        ),
      key: 'risk',
      label: `Risk level: ${options.riskOptions.find((item) => item.value === visitorLogTable.risk)?.label}`,
      hidden: !visitorLogTable.risk,
    },
    {
      onRemove: () =>
        dispatch(
          visitorLogSlice.actions.handleVisitorLogTable({
            ...visitorLogTable,
            countriesCode: [],
            page: 1,
          }),
        ),
      key: 'countriesCode',
      label: `Country: ${truncateString(
        countryFilterVisitor
          .filter((item) => visitorLogTable.countriesCode?.includes(item.value))
          ?.map((item) => item.label)
          .toString(),
        100,
      )}`,
      hidden: (visitorLogTable.countriesCode || '').length === 0,
    },
    {
      onRemove: () =>
        dispatch(
          visitorLogSlice.actions.handleVisitorLogTable({
            ...visitorLogTable,
            providers: [],
            page: 1,
          }),
        ),
      key: 'isp',
      label: `ISP: ${truncateString(
        (providerFilterAllowed || [])
          .filter((item) => visitorLogTable.providers?.includes(item.value))
          ?.map((item) => item.label)
          .toString(),
        100,
      )}`,
      hidden: (visitorLogTable.providers || '').length === 0,
    },
    {
      onRemove: () =>
        dispatch(
          visitorLogSlice.actions.handleVisitorLogTable({
            ...visitorLogTable,
            locations: [],
            page: 1,
          }),
        ),
      key: 'location',
      label: `Location: ${truncateString(
        (locationFilterAllowed || [])
          .filter((item) => visitorLogTable.locations?.includes(item.value))
          ?.map((item) => item.label)
          .toString(),
        100,
      )}`,
      hidden: (visitorLogTable.locations || '').length === 0,
    },
  ];

  // Blocked
  const blockHistoryTable = useSelector(blockHistorySelector);
  const filtersBlocked: FilterInterface[] = [
    {
      key: 'perPage',
      label: 'Per page',
      filter: (
        <ChoiceList title="Per page" titleHidden choices={perPageOptions} selected={[perPage]} onChange={handleOnChangePerPage} />
      ),
      shortcut: true,
    },
    {
      key: 'country',
      label: 'Country',
      filter: (
        <AutoCompleteWithTag
          options={countryFilterBlock}
          label={'Country'}
          selectedOptions={blockHistoryTable.countriesCode || []}
          setSelectedOptions={(selected: string[]) => {
            dispatch(
              dashboardSlice.actions.handleBlockHistoryTable({
                ...blockHistoryTable,
                countriesCode: selected,
                page: 1,
              }),
            );
          }}
          allowMultiple={true}
        />
      ),
      shortcut: true,
    },
    {
      key: 'isp',
      label: 'ISP',
      suffix: plan === Enum.UserPlan.FREE ? <TooltipUpdateHigherPlan /> : undefined,
      disabled: plan === Enum.UserPlan.FREE,
      filter: (
        <AutoCompleteWithTag
          options={providerFilterBlocked || []}
          label={'ISP'}
          selectedOptions={blockHistoryTable.providers || []}
          setSelectedOptions={(selected: string[]) => {
            dispatch(
              dashboardSlice.actions.handleBlockHistoryTable({
                ...blockHistoryTable,
                providers: selected,
                page: 1,
              }),
            );
          }}
          allowMultiple={true}
        />
      ),
      shortcut: true,
    },
    {
      key: 'location',
      label: 'Location',
      shortcut: true,
      filter: (
        <AutoCompleteWithTag
          options={locationFilterBlocked || []}
          label={'Location'}
          selectedOptions={blockHistoryTable.locations || []}
          setSelectedOptions={(selected: string[]) => {
            dispatch(
              dashboardSlice.actions.handleBlockHistoryTable({
                ...blockHistoryTable,
                locations: selected,
                page: 1,
              }),
            );
          }}
          allowMultiple={true}
        />
      ),
    },
    {
      key: 'risk',
      label: 'Risk level',
      shortcut: true,
      suffix: plan === Enum.UserPlan.FREE ? <TooltipUpdateHigherPlan /> : undefined,
      disabled: plan === Enum.UserPlan.FREE,
      filter: (
        <ChoiceList
          title="Risk level"
          titleHidden
          choices={options.riskOptions}
          selected={[blockHistoryTable.risk || '']}
          onChange={(value) => {
            dispatch(
              dashboardSlice.actions.handleBlockHistoryTable({
                ...blockHistoryTable,
                risk: value[0] || undefined,
                page: 1,
              }),
            );
          }}
        />
      ),
    },
  ];

  const appliedFiltersBlocked = [
    {
      onRemove: () => {
        dispatch(visitorLogSlice.actions.handlePerPage('10'));
        dispatch(
          dashboardSlice.actions.handleBlockHistoryTable({
            ...blockHistoryTable,
            page: 1,
          }),
        );
      },
      key: 'perPage',
      label: 'Record per page: ' + perPage,
      hidden: perPage === '10',
    },
    {
      onRemove: () =>
        dispatch(
          dashboardSlice.actions.handleBlockHistoryTable({
            ...blockHistoryTable,
            countriesCode: [],
            page: 1,
          }),
        ),
      key: 'country',
      label: `Country: ${truncateString(
        countryFilterBlock
          .filter((item) => blockHistoryTable.countriesCode?.includes(item.value))
          ?.map((item) => item.label)
          .toString(),
        100,
      )}`,
      hidden: (blockHistoryTable.countriesCode || '').length === 0,
    },
    {
      onRemove: () =>
        dispatch(
          dashboardSlice.actions.handleBlockHistoryTable({
            ...blockHistoryTable,
            providers: undefined,
            page: 1,
          }),
        ),
      key: 'isp',
      label: `ISP: ${truncateString(
        (providerFilterBlocked || [])
          .filter((item) => blockHistoryTable.providers?.includes(item.value))
          ?.map((item) => item.label)
          .toString(),
        100,
      )}`,
      hidden: (blockHistoryTable.providers || '').length === 0,
    },
    {
      onRemove: () =>
        dispatch(
          dashboardSlice.actions.handleBlockHistoryTable({
            ...blockHistoryTable,
            locations: [],
            page: 1,
          }),
        ),
      key: 'location',
      label: `Location: ${truncateString(
        (locationFilterBlocked || [])
          .filter((item) => blockHistoryTable.locations?.includes(item.value))
          ?.map((item) => item.label)
          .toString(),
        100,
      )}`,
      hidden: (blockHistoryTable.locations || '').length === 0,
    },
    {
      onRemove: () =>
        dispatch(
          dashboardSlice.actions.handleBlockHistoryTable({
            ...blockHistoryTable,
            risk: undefined,
            page: 1,
          }),
        ),
      key: 'risk',
      label: `Risk level: ${options.riskOptions.find((item) => item.value === blockHistoryTable.risk)?.label}`,
      hidden: !blockHistoryTable.risk,
    },
  ];

  return {
    accessAll: {
      filters: filtersAllAccess,
      appliedFilters: appliedFiltersAllAccess,
    },
    visitors: {
      filters: filtersVisitors,
      appliedFilters: appliedFiltersVisitors,
    },
    Blocked: {
      filters: filtersBlocked,
      appliedFilters: appliedFiltersBlocked,
    },
  };
};

export default Filters;
