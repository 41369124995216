import styled from 'styled-components';

export const GeneralSettingsStyled = styled.div`
  .customize-code {
    overflow: auto;
    max-width: 42rem;
    position: relative;
    max-height: 10rem;
  }
  .copy-code {
    position: absolute;
    top: 1.5rem;
    right: 1rem;
  }
  pre {
    padding: 8px;
    width: fit-content;
    background-color: rgb(246, 246, 247);
    border-radius: 1rem;
  }
  code {
    background-color: rgba(235, 236, 239, 1);
    border-radius: 0.5rem;
    padding: 2px;
  }
`;
