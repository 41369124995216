/* eslint-disable jsx-a11y/img-redundant-alt */
import { images } from '@/asset';
import { Text } from '@shopify/polaris';
const StepFour = () => {
    return (
        <div className="tab-pane">
            <Text as="h3" variant="heading2xl">
                Hurray! You are all set!
            </Text>
            <div>
                <div className="image-center">
                    <img loading="lazy" src={images.insurance} alt="Responsive image" />
                </div>
                <p className="welcome-description" style={{ fontSize: '16px' }}>
                    <Text as="h3" variant="bodyMd">
                        Say goodbye to potential threats and maintain a safer online selling experience with Blockify!
                    </Text>
                </p>
            </div>
        </div>
    );
};

export default StepFour;
