import CustomButton from '@/components/CustomButton';
import { config } from '@/config';
import { dateToTimeStamp } from '@/helpers';
import slice from '@/redux/slice';
import { allAccessSelector, tabsTableSelector } from '@/redux/slice/visitorAnalytics.slice';
import {
  Banner,
  Card,
  IndexFilters,
  IndexFiltersMode,
  IndexFiltersProps,
  Link,
  Tooltip,
  useSetIndexFiltersMode,
} from '@shopify/polaris';
import { ExportMinor } from '@shopify/polaris-icons';
import { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AllAccessTable from './AllAccess';
import TableBlocked from './BlockedTable';
import useConfigTable from './ConfigTable';
import TableVisitorLog from './VisitorAnalytics';
import { apiCaller } from '@/redux/query';
import { Enum } from '@/constants';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
const TableAnalytics = () => {
  const dispatch = useDispatch();
  const configTable = useConfigTable();
  const { mode, setMode } = useSetIndexFiltersMode();
  const dataSettings = useSelector(dataSettingsSelector);
  const selected = useSelector(tabsTableSelector);
  const allAccess = useSelector(allAccessSelector);
  const blockedIps = apiCaller.useFetchChartBlockQuery({
    endDate: dateToTimeStamp(allAccess.endDate),
    startDate: dateToTimeStamp(allAccess.startDate),
  });
  const visitors = apiCaller.useFetchChartAnalyticsQuery({
    endDate: dateToTimeStamp(allAccess.endDate),
    startDate: dateToTimeStamp(allAccess.startDate),
  });
  const handleTabChange = useCallback(
    (selectedTabIndex: number) => dispatch(slice.visitorAnalyticsSlice.actions.handleTableTab(selectedTabIndex)),
    [dispatch],
  );

  const totalVisitorFreePlan = (blockedIps.data?.data.totalBlocked || 0) + (visitors.data?.data.totalVisit || 0);
  const percentageOfVisitor = Number(((totalVisitorFreePlan / 200) * 100).toFixed(2));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tabs = [
    {
      id: 'allAccess',
      content: 'All',
      component: <AllAccessTable />,
      exportUrl: `${process.env.REACT_APP_API_END_POINT}visitor/export/all?instance=${config.instance
        }&startDate=${dateToTimeStamp(allAccess.startDate)}&endDate=${dateToTimeStamp(allAccess.endDate)}`,
    },
    {
      id: 'visitorLog',
      content: 'Allowed',
      component: <TableVisitorLog />,
      exportUrl: `${process.env.REACT_APP_API_END_POINT}visitor/export?instance=${config.instance}&startDate=${dateToTimeStamp(
        allAccess.startDate,
      )}&endDate=${dateToTimeStamp(allAccess.endDate)}`,
    },
    {
      id: 'blocked',
      content: 'Blocked',
      component: <TableBlocked />,
      exportUrl: `${process.env.REACT_APP_API_END_POINT}visitor/block/export?instance=${config.instance
        }&startDate=${dateToTimeStamp(allAccess.startDate)}&endDate=${dateToTimeStamp(allAccess.endDate)}`,
    },
  ];

  const configFilter = useMemo(() => {
    const key = tabs[selected].id;
    const result = {
      ...configTable[key as keyof typeof configTable],
    };
    return result;
  }, [configTable, selected, tabs]);

  return (
    <div className="table-container">
      <Card padding={'100'}>
        <IndexFilters
          {...configFilter}
          sortOptions={configFilter.sortOptions as IndexFiltersProps['sortOptions']}
          cancelAction={{
            onAction: () => { },
          }}
          tabs={tabs}
          selected={selected}
          onSelect={handleTabChange}
          mode={mode}
          setMode={setMode}
          canCreateNewView={false}
        />
        <div className="export-btn" style={{ display: mode === IndexFiltersMode.Default ? 'block' : 'none' }}>
          <Tooltip content={'Export csv'}>
            <CustomButton icon={ExportMinor} url={tabs[selected].exportUrl} target="_blank" />
          </Tooltip>
        </div>
        {dataSettings?.settings.user.plan === Enum.UserPlan.FREE ? (
          <div className="mt-8 mb-8">
            <Banner tone={percentageOfVisitor > 80 ? 'critical' : 'warning'}>
              Your website has hit <b>{totalVisitorFreePlan}</b> out of the total 200 visits limited in the Free plan, reaching <b>{Math.min(percentageOfVisitor, 100)}%</b>{' '}
              of our Free plan’s data capacity.{' '}
              <Link
                onClick={() =>
                  window.open(
                    `https://www.wix.com/apps/upgrade/${process.env.REACT_APP_APP_ID}?appInstanceId=${dataSettings?.settings.instanceId}`,
                  )
                }
              >
                Upgrade now
              </Link>{' '}
              to access Internet Provider details, Risk Score, and continue to enjoy full analytics
            </Banner>
          </div>
        ) : null}
        {tabs[selected].component}
      </Card>
    </div>
  );
};

export default memo(TableAnalytics);
