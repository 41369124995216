import { images } from '@/asset';
import { ISettings } from '@/types/components';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
export interface ITemplateInput {
  titleColor: string;
  superTitleColor: string;
  contentColor: string;
  backgroundColor: string;
  backgroundGradientFrom: string;
  backgroundGradientTo: string;
}
// Define the initial state using that type
const initialState: {
  selected: Array<string>;
  selectedTab: number;
  selectedTemplateId: number;
  customTemplate: ISettings.CustomTemplate;
  customTemplateSaved: ISettings.CustomTemplate;
  input: ITemplateInput;
  css: string;
  cssBackup: string;
  templateId: number;
} = {
  selected: ['general'],
  selectedTab: 0,
  selectedTemplateId: 1,
  customTemplate: {
    title: {
      active: true,
      text: 'Access Denied',
      color: '#000',
    },
    description: {
      active: true,
      text: 'The site owner may have set restrictions that prevent you from accessing the site.Please contact the site owner for access.',
      color: '#000',
    },
    background: {
      active: true,
      colorFrom: '#fff',
      colorTo: '#fff',
      type: 1,
      value: '#fff',
    },
    superTitle: {
      active: false,
      text: '',
      color: '#000',
    },
    logoImage: {
      active: true,
      value: images.blockify,
    },
  },
  customTemplateSaved: {
    title: {
      active: true,
      text: 'Access Denied',
      color: '#000',
    },
    description: {
      active: true,
      text: 'The site owner may have set restrictions that prevent you from accessing the site.Please contact the site owner for access.',
      color: '#000',
    },
    background: {
      active: true,
      colorFrom: null,
      colorTo: null,
      type: 1,
      value: '#fff',
    },
    superTitle: {
      active: false,
      text: '',
      color: '#000',
    },
    logoImage: {
      active: true,
      value: images.blockify,
    },
  },
  input: {
    superTitleColor: '#000000',
    titleColor: '#000000',
    contentColor: '#000000',
    backgroundColor: '#ffffff',
    backgroundGradientFrom: '#ffffff',
    backgroundGradientTo: '#ffffff',
  },
  css: '',
  cssBackup: '',
  templateId: 0,
};

export const settingsSlice = createSlice({
  name: 'settings',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleTemplate: (state, action: PayloadAction<ISettings.CustomTemplate>) => {
      state.customTemplate = action.payload;
    },
    handleTemplateSaved: (state, action: PayloadAction<ISettings.CustomTemplate>) => {
      state.customTemplateSaved = action.payload;
    },
    handleInput: (state, action: PayloadAction<ITemplateInput>) => {
      state.input = action.payload;
    },

    handleSelected: (state, action: PayloadAction<Array<string>>) => {
      state.selected = action.payload;
    },
    handleSelectedTab: (state, action: PayloadAction<number>) => {
      state.selectedTab = action.payload;
    },
    handleSelectedTemplateId: (state, action: PayloadAction<number>) => {
      state.selectedTemplateId = action.payload;
    },
    handleCss: (state, action: PayloadAction<string>) => {
      state.css = action.payload;
    },
    handleCssBackup: (state, action: PayloadAction<string>) => {
      state.cssBackup = action.payload;
    },
    handleTemplateId: (state, action: PayloadAction<number>) => {
      state.templateId = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const templateSelector = createSelector(
  (state: RootState) => state.setting,
  (state) => state.customTemplate,
);
export const templateSavedSelector = createSelector(
  (state: RootState) => state.setting,
  (state) => state.customTemplateSaved,
);

export const inputTemplateSelector = createSelector(
  (state: RootState) => state.setting,
  (state) => state.input,
);

export const selectedSelector = createSelector(
  (state: RootState) => state.setting,
  (state) => state.selected,
);

export const selectedTabSelector = createSelector(
  (state: RootState) => state.setting,
  (state) => state.selectedTab,
);

export const selectedTemplateIdSelector = createSelector(
  (state: RootState) => state.setting,
  (state) => state.selectedTemplateId,
);

export const cssSelector = createSelector(
  (state: RootState) => state.setting,
  (state) => state.css,
);
export const cssBackupSelector = createSelector(
  (state: RootState) => state.setting,
  (state) => state.cssBackup,
);
export const templateIdSelector = createSelector(
  (state: RootState) => state.setting,
  (state) => state.templateId,
);

export default settingsSlice;
