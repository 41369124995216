import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IResponseApi } from '@/types/api/response.api';
import { config } from '@/config';
const endPoint = process.env.REACT_APP_API_LOCATION_END_POINT;
const params = {
  shop: config.shop,
  urlParams: JSON.stringify(config.urlParams),
};

export const fetchLocation = createApi({
  reducerPath: 'fetchLocation',
  refetchOnMountOrArgChange: 30,
  baseQuery: fetchBaseQuery({
    baseUrl: endPoint,
    prepareHeaders: (headers) => {
      if (process.env.REACT_APP_ENV === 'production') {
        headers.set('Content-Security-Policy', `frame-ancestors https://${params.shop} https://admin.shopify.com`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getCountry: builder.query<IResponseApi.IGetListCountry, void>({
      query: () => {
        return {
          url: `location/countries`,
          method: 'GET',
          params: { ...params, page: 1, perPage: 1000 },
        };
      },
    }),
    getState: builder.query<IResponseApi.IGetListState, { countryCode: string }>({
      query: (input) => {
        return {
          url: `location/states`,
          method: 'GET',
          params: { ...params, ...input, page: 1, perPage: 1000 },
        };
      },
    }),
    getCity: builder.query<
      IResponseApi.IGetListCity,
      {
        countryCode: string;
        regionName: string;
      }
    >({
      query: (input) => {
        return {
          url: `location/cities`,
          method: 'GET',
          params: { ...params, ...input, page: 1, perPage: 1000 },
        };
      },
    }),
  }),
});
