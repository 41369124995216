/* eslint-disable no-useless-escape */
import { UserPlan } from '@/constants/enum';
import store from '@/redux/store';
import { IOptions, IToast } from '@/types/components';
import { HSBColor, hexToRgb, rgbToHsb } from '@shopify/polaris';
import dayjs from 'dayjs';

// const ipv4Pattern = /\b((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}\b/,
const ipv6Pattern =
    /(?:^|(?<=\s))(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))(?=\s|$)/,
  // urlPattern = /^https:\/\/[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//= ]*)$/,
  ipv4StartPattern = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){1,4}$/;
const ipv4Pattern = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}$/;
export const getSomeDaysAgo = (day: number): Date => {
  const date = new Date();
  date.setDate(date.getDate() - day);
  date.setHours(0, 0, 0);
  return date;
};

export const mergeArrays = (arr1: any, arr2: any) => {
  const merged = arr1.concat(arr2);
  const uniqueValues = merged.reduce((acc: any, item: any) => {
    const existingItem = acc.find((i: any) => i.value === item.value);
    if (!existingItem) {
      acc.push(item);
    }
    return acc;
  }, []);

  return uniqueValues;
};

export const getLastMonth = () => {
  const firtDate = new Date();
  firtDate.setDate(1);
  firtDate.setMonth(firtDate.getMonth() - 1);
  const lastDate = new Date();
  lastDate.setDate(0);
  return {
    start: firtDate,
    end: lastDate,
  };
};

export const getLastSomesMonth = (month: number) => {
  const firtDate = new Date();
  firtDate.setDate(1);
  firtDate.setMonth(firtDate.getMonth() - month);
  const lastDate = new Date();
  lastDate.setMonth(lastDate.getMonth() - month + 1);
  lastDate.setDate(0);
  return {
    start: firtDate,
    end: lastDate,
  };
};

export const getLastYear = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 1);
  return date;
};

export const validateIp = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/,
    );
};

export const validateIpAddress = (ip: string): boolean => {
  return ipv4Pattern.test(ip) || ipv6Pattern.test(ip);
};

export const validateListIp = (ips: string): boolean => {
  const listIp = ips.replace(/\s+/g, '').split(',');
  for (let ip of listIp) {
    if (!ipv4Pattern.test(ip) && !ipv6Pattern.test(ip)) return false;
  }
  return true;
};

export const validateIpStartWith = (ip: string): boolean => {
  return ipv4StartPattern.test(ip.replace(/\.+$/, '')) || ipv6Pattern.test(ip);
};

export const validateListIpStartWith = (ips: string): boolean => {
  if (!ips) return false;
  const listIp = ips.replace(/\s+/g, '').split(',');
  for (let ip of listIp) {
    // bỏ các dấu chấm ở cuối chuỗi
    if (!ipv4StartPattern.test(ip.replace(/\.+$/, '')) && !ipv6Pattern.test(ip)) return false;
  }
  return true;
};

export function validateUrl(url: string) {
  const regex = /[(www\.)?a-zA-Z0-9@:%._\+\-~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i;
  const result = url.toLowerCase().match(regex);
  if (result === null) return false;
  else return true;
}

export function deepObjectEqual(object1: any, object2: any) {
  return JSON.stringify(object1) === JSON.stringify(object2);
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const hexToHSB = (color: string): HSBColor => {
  const rbgColor = hexToRgb(color);
  const hsbColor = rgbToHsb(rbgColor);
  return hsbColor;
};

export const handleToastMutation = (res: any): IToast => {
  if ('data' in res) {
    if (res.data.state !== 1) {
      return {
        isOpen: true,
        content: res.data.msg || 'Failed',
        error: true,
      };
    } else {
      return {
        isOpen: true,
        content: res.data.msg || 'Saved',
        error: false,
      };
    }
  } else {
    return {
      isOpen: true,
      content: res.data.msg || 'something happened',
      error: false,
    };
  }
};

export const convertToCamelCase = (str: string): string =>
  str
    .replace(/"/g, '')
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
    .replace(/(\s|\b)(\w)/g, (m, space, chr) => chr.toLowerCase());

// Check if your browser blocks localStorage
export const isLocalStorageSupported = () => {
  try {
    const testKey = '__test__';
    localStorage.setItem(testKey, testKey);
    localStorage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
};

export const truncateString = (str: string, maxLength: number) => {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + '...';
  }
  return str;
};

export const formatDate = (date: number | Date, format?: string) => {
  return dayjs(typeof date === 'number' ? date * 1000 : date).format(format ? format : 'D MMM YYYY, HH:mm');
};

export const dateToTimeStamp = (date: Date) => {
  return dayjs(date).unix() * 1000;
};

export const secondToTime = (second: number) => {
  return new Date(second * 1000).toISOString().slice(11, 19);
};

export const disablePlan = (plans: UserPlan[]) => {
  const plan = store.getState().dataSettings.data?.settings.user.plan;
  const index = plans.findIndex((item) => item === plan);
  return index !== -1;
};

export const openCrisp = () => {
  try {
    $crisp.push(['do', 'chat:open']);
  } catch (error) {
    console.log(error);
  }
};

export const checkShowErrorInline = (
  res: any,
): {
  status: boolean;
  msg: string;
} => {
  try {
    if ('data' in res) {
      return {
        status: res.data.state !== 1,
        msg: res.data.msg,
      };
    } else {
      return {
        status: true,
        msg: res.error.data.message.toString() || 'Something happened',
      };
    }
  } catch (err) {
    return {
      status: true,
      msg: 'Something happened',
    };
  }
};

export const formatCreatedAt = (createdAt: number) => {
  const currentTime = new Date().getTime();
  const timeDiff = currentTime - createdAt;
  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (minutes <= 1) {
    return `1 minute ago`;
  } else if (minutes < 60) {
    return `${minutes} minutes ago`;
  } else if (hours <= 1) {
    return `${hours} hour ago`;
  } else if (hours < 24) {
    return `${hours} hours ago`;
  } else if (days <= 1) {
    return `${days} day ago`;
  } else if (days < 30) {
    return `${days} days ago`;
  } else if (months <= 1) {
    return `${months} month ago`;
  } else if (months < 12) {
    return `${months} months ago`;
  } else if (years <= 1) {
    return `${years} year ago`;
  } else {
    return `${years} years ago`;
  }
};

export const checkConditionErrorInline = (
  res: any,
): {
  status: boolean;
  msg: string;
  failureRules: [
    {
      errMsg: string;
      errValue: string;
    },
  ];
  url: string;
} => {
  try {
    if ('data' in res) {
      return {
        status: res.data.state !== 1,
        msg: res.data.msg,
        failureRules: res.data.failureRules,
        url: res.data.url,
      };
    } else {
      return {
        status: true,
        msg: res.error.data.message.toString() || 'Something happened',
        failureRules: res.data.failureRules,
        url: res.data.url,
      };
    }
  } catch (err) {
    return {
      status: true,
      msg: 'Something happened',
      failureRules: res.data.failureRules,
      url: res.data.url,
    };
  }
};
export function removeFalsyValues<T extends Record<string, any>>(obj: T): Partial<T> {
  const result: Partial<T> = {};

  (Object.keys(obj) as Array<keyof T>).forEach((key) => {
    const value = obj[key];
    if (Boolean(value) && (!Array.isArray(value) || value.length > 0)) {
      result[key] = value;
    }
  });

  return result;
}
export function uniq(a: Array<any>) {
  return Array.from(new Set(a));
}
export const getOptionsSelectedRemove = (newValue: Array<string>, oldValue: Array<IOptions>) => {
  const optionSelected = oldValue.filter((option) => newValue.includes(option.value));
  return optionSelected;
};
export const getOptionsSelectedAdd = (newValue: Array<string>, oldValue: Array<IOptions>, options: Array<IOptions>) => {
  const selectedOptions = options.filter((option) => newValue.includes(option.value));
  return [...oldValue, ...selectedOptions].filter((item, index, self) => index === self.findIndex((t) => t.value === item.value));
};
