import CustomButton from '@/components/CustomButton';
import Card from '@/components/card';
import { config } from '@/config';
import { capitalizeFirstLetter } from '@/helpers';
import { apiCaller } from '@/redux/query';
import { Icon, Loading, Text } from '@shopify/polaris';
import { ProfileMajor } from '@shopify/polaris-icons';
import { memo } from 'react';

const AccountPlanSetting = () => {
  const { data, isLoading } = apiCaller.useGetGeneralDetailQuery(config.shop);
  const link = `https://www.wix.com/apps/upgrade/${process.env.REACT_APP_APP_ID}?appInstanceId=${data?.settings.instanceId}`;
  return (
    <Card title="Account details">
      {isLoading ? <Loading /> : null}
      <div className="d-flex">
        <div className="mr-8">
          <Icon source={ProfileMajor} tone="base" />
        </div>
        <Text as="h6" variant="headingMd">
          Your plan: {capitalizeFirstLetter(data?.settings.user.plan || '')}
        </Text>
      </div>

      <div className="d-flex flex-end mt-16">
        <CustomButton url={link} removeUnderline external variant="primary">
          Change plan
        </CustomButton>
      </div>
    </Card>
  );
};
export default memo(AccountPlanSetting);
