/* eslint-disable react-hooks/exhaustive-deps */
import { config } from '@/config';
import { PATH } from '@/constants/path';
import { apiCaller } from '@/redux/query';
import { Card, Button, SkeletonBodyText, Text } from '@shopify/polaris';
import React, { useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StepFour from './components/StepFour';
import StepOne from './components/StepOne';
import StepThree from './components/StepThree';
import StepTwo from './components/StepTwo';
import { WelcomeStyled } from './styled';

const stepItems = [
  {
    key: 1,
    title: 'Welcome',
    content: <StepOne />,
  },
  {
    key: 2,
    title: 'Publish site',
    content: <StepTwo />,
  },
  {
    key: 3,
    title: 'First experience',
    content: <StepThree />,
  },
  {
    key: 4,
    title: 'Finish',
    content: <StepFour />,
  },
];

export const welcomeContext = React.createContext<{ isChecked: boolean; setIsChecked: any }>({
  isChecked: false,
  setIsChecked: () => {},
});

const Welcome = (): JSX.Element => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [step, setStep] = useState(1);
  const [trigger, { isLoading }] = apiCaller.useLazyGetGeneralDetailQuery();
  const [updateWelcomeStep, updateWelcomeStepStatus] = apiCaller.useUpdateWelcomeStepMutation();
  const handleChangeStep = (stepInput: number) => () => {
    if (stepInput > 4) {
      handleSkip();
    } else {
      setStep(stepInput);
    }
  };
  const handleSkip = () => {
    updateWelcomeStep(step).then(() => {
      trigger(config.shop).then((res) => {
        if ('data' in res) {
          if (!res.data?.settings.displayWelcome) {
            navigate(PATH.DASHBOARD);
          }
        }
      });
    });
  };
  useLayoutEffect(() => {
    trigger(config.shop).then((res) => {
      if ('data' in res) {
        if (!res.data?.settings.displayWelcome) {
          navigate(PATH.DASHBOARD);
        }
        setStep(res.data?.settings.welcomeStep || 1);
      }
    });
  }, []);

  return (
    <welcomeContext.Provider value={{ isChecked, setIsChecked }}>
      <WelcomeStyled>
        {isLoading ? (
          <Card>
            <SkeletonBodyText lines={20} />
          </Card>
        ) : (
          <Card>
            <div className="welcome-container">
              <ul>
                <div className="welcome-bridge" />
                {stepItems.map((item) => {
                  return (
                    <li
                      key={item.key}
                      onClick={handleChangeStep(item.key)}
                      className={step > item.key ? 'completed' : step === item.key ? 'active' : ''}
                    >
                      <div className="step-container" data-toggle="tab" aria-expanded="false">
                        <div className="step-number">{item.key}</div>
                        <Text as="h3" variant="headingMd">
                          {item.title}
                        </Text>
                      </div>
                    </li>
                  );
                })}
              </ul>
              <div className="tab-content d-block">
                <div className="tab-panel">{stepItems.find((item) => item.key === step)?.content}</div>
              </div>
              <div className="button-center mt-20">
                <Button
                  loading={updateWelcomeStepStatus.isLoading && step === 4}
                  size="medium"
                  onClick={handleChangeStep(step + 1)}
                  variant="primary"
                >
                  {step === 1 ? 'Get Started' : step === 4 ? 'Start Now' : 'Next'}
                </Button>

                {step !== 4 ? (
                  <div className="mt-16">
                    <Button loading={updateWelcomeStepStatus.isLoading} variant="plain" onClick={handleSkip}>
                      Skip
                    </Button>
                  </div>
                ) : null}
              </div>
            </div>
          </Card>
        )}
      </WelcomeStyled>
    </welcomeContext.Provider>
  );
};
export default Welcome;
