export enum ListType {
  BlackList = '1',
  WhiteList = '2',
}

export enum ActionType {
  Block = '1',
  Redirect = '2',
  Whitelist = '0',
}

export enum CriteriaType {
  IpAddress = '1',
  IpAddressStartWith = '4',
  Country = '3',
  Province = '2',
  ISP = '5',
  ReferralLink = '6',
  UserAgent = '7',
  IpRanges = '8',
  SpecificPage = '9',
}

enum BackgroundType {
  Color = '1',
  Image = '2',
  GRADIENT = '3',
}

enum RiskType {
  Safe = 'safe',
  Risky = 'risky',
  Dangerous = 'dangerous',
}

enum RiskLevel {
  Safe = 34,
  Risky = 66,
  Dangerous = 101,
}

enum StatusVisitorsLogType {
  Allow = 'allow',
  Block = 'block',
}

enum TypeList {
  Allowed = 'allowed',
  Blocked = 'blocked',
}

export enum TypeVisitorsLogType {
  Other = 'Other',
  Residential = 'Residential',
  Wireless = 'Wireless',
  Business = 'Business',
  VPN = 'VPN',
  TOR = 'TOR',
}

export enum UserPlan {
  FREE = 'free',
  PREMIUM = 'premium',
  ENTERPRISE = 'enterprise',
}

export enum MediaScreen {
  LARGE = 1440,
  MEDIUM = 1040,
  SMALL = 767,
  EXTRA_SMALL = 489,
}

export enum SortType {
  DESC = 'desc',
  ASC = 'asc',
}
export enum SortByType {
  TOTAL_ACCESS = 'totalAccess',
  ACCESS_AT = 'accessAt',
  LAST_SEEN_ON = 'lastSeenOn',
}

export enum ReviewType {
  ACCEPT = 'accept',
  REJECT = 'reject',
}
export enum DeviceType {
  SMART_PHONE = 1,
  TABLET = 2,
  PHABLET = 3,
  DESKTOP = 4,
}

export enum LimitRuleFreePlan {
  LIMIT_NUMBER = 6,
}

export enum LimitNumberOfIP {
  LIMIT = 100,
}

export enum UserPermission {
  View = 'view',
  ViewSensitive = 'view_sensitive',
  Edit = 'edit',
  FullAccess = 'full_access',
}

export enum UserRole {
  Admin = 'admin',
  Guest = 'guest',
  Support = 'supporter',
}

export enum App {
  Synctrack = 'synctrack',
  Blockify = 'blockify',
  Floatify = 'floatify',
  OT = 'ordertracking',
  Return = 'returnsdrive',
}

export enum BREAKPOINT {
  XS = 490,
  SM = 768,
  MD = 1040,
  LG = 1800,
  XL = 1920,
}

export enum StatusIpLog {
  ALLOW = 'allow',
  BLOCK = 'block',
}

export enum Position {
  OVER_VIEW = 'over_view',
  CHART_DEMO = 'chart/allow',
  HEATMAP_DEMO = 'heatmap/allow',
  ALL_ACCESS_DEMO = 'list/all-access',
  VA_DEMO = 'list/allow',
}

export enum VPN {
  TRUE = 1,
  FALSE = 0,
}

export default {
  ListType,
  ActionType,
  CriteriaType,
  BackgroundType,
  RiskType,
  RiskLevel,
  StatusVisitorsLogType,
  TypeVisitorsLogType,
  UserPlan,
  MediaScreen,
  SortType,
  ReviewType,
  DeviceType,
  LimitRuleFreePlan,
  SortByType,
  LimitNumberOfIP,
  Position,
  TypeList,
  VPN
};
