import { Icon, Tooltip } from '@shopify/polaris';
import { InfoMinor } from '@shopify/polaris-icons';
interface IProps {
  url?: string;
}
const TooltipUpdateHigherPlan = ({ url }: IProps) => {
  return (
    <div
      className={url ? 'pointer' : ''}
      onClick={() => {
        if (url) window.open(url, '_blank');
      }}
    >
      <Tooltip content="Upgrade to the Premium plan or above to get more information">
        <Icon source={InfoMinor} tone="subdued" />
      </Tooltip>
    </div>
  );
};

export default TooltipUpdateHigherPlan;
