import { Enum } from '@/constants';
import { BREAKPOINT } from '@/constants/enum';
import useScope from '@/hooks/Scope';
import { apiCaller } from '@/redux/query';
import { ISettingToggle } from '@/types/components';
import { Badge, Button, Card, InlineGrid, SkeletonBodyText, SkeletonDisplayText, Tooltip } from '@shopify/polaris';
import { memo } from 'react';
import { useMediaQuery } from 'react-responsive';
import BoldText from '../BoldText';
import RegularText from '../RegularText';
import { CustomSettingToggleStyled } from './styled';

const CustomSettingToggle = ({ ...props }: ISettingToggle.IProps) => {
  const { isLoading } = apiCaller.useGetGeneralDetailQuery(undefined);
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT.SM });
  const scope = useScope();
  return (
    <CustomSettingToggleStyled>
      <Card>
        <InlineGrid columns={isMobile ? 1 : ['twoThirds', 'oneThird']}>
          <div>
            <div className="toggle-settings-title">
              <BoldText>{props.title}</BoldText>
              <div className="ml-4">
                {isLoading && props.settingToggleProps.action?.loading ? (
                  <SkeletonBodyText lines={1} />
                ) : (
                  <Badge tone={props.settingToggleProps.enabled ? 'success' : 'critical'}>
                    {props.settingToggleProps.enabled ? 'On' : 'Off'}
                  </Badge>
                )}
              </div>
            </div>
            {props.children ? (
              <div className="mt-8">
                {typeof props.children === 'string' ? <RegularText>{props.children}</RegularText> : props.children}
              </div>
            ) : null}
          </div>
          <div className="toggle-btn">
            {isLoading && props.settingToggleProps.action?.loading ? (
              <SkeletonDisplayText size="small" />
            ) : props.disableStatus ? (
              <Tooltip
                content={`Available on ${props.minActivePlan && props.minActivePlan.charAt(0).toUpperCase() + props.minActivePlan.slice(1)} plan${props.minActivePlan === Enum.UserPlan.ENTERPRISE ? '' : ' or above'
                  }`}
              >
                <Button
                  disabled={props.disableStatus}
                  onClick={props.settingToggleProps.action?.onAction}
                  loading={props.settingToggleProps.action?.loading}
                  variant={props.settingToggleProps.enabled ? undefined : 'primary'}
                >
                  {props.settingToggleProps.enabled ? 'Turn off' : 'Turn on'}
                </Button>
              </Tooltip>
            ) : (
              <Button
                disabled={props.disableStatus || scope.isViewOnly}
                onClick={props.settingToggleProps.action?.onAction}
                loading={props.settingToggleProps.action?.loading}
                variant={props.settingToggleProps.enabled ? undefined : 'primary'}
              >
                {props.settingToggleProps.enabled ? 'Turn off' : 'Turn on'}
              </Button>
            )}
          </div>
        </InlineGrid>
      </Card>
    </CustomSettingToggleStyled>
  );
};

export default memo(CustomSettingToggle);
