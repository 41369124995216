import { Text } from '@shopify/polaris';
import { memo } from 'react';
const RegularText = ({ children }: { children: React.ReactNode }) => {
  return (
    <Text as="span" variant="bodyMd">
      {children}
    </Text>
  );
};
export default memo(RegularText);
