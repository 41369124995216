import BlockPageStoreFront from '@/components/BlockPageStoreFront';
import CustomButton from '@/components/CustomButton';
import { config } from '@/config';
import { PATH } from '@/constants';
import { Card } from '@shopify/polaris';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';

const EditTemplateBtn = () => {
  const navigate = useNavigate();
  return (
    <CustomButton
      variant="primary"
      onClick={() => {
        navigate(PATH.CUSTOMTEMPLATE);
      }}
    >
      Edit template
    </CustomButton>
  );
};

const EditTemplateBtnAppBridge = () => {
  const navigate = useNavigate();
  return (
    <CustomButton
      variant="primary"
      onClick={() => {
        navigate(PATH.CUSTOMTEMPLATE);
      }}
    >
      Edit template
    </CustomButton>
  );
};

const CustomTemplate = () => {
  return (
    <div style={{ height: 'fit-content' }}>
      <Card>
        <BlockPageStoreFront smallScreen={true} focusId={''} />
        <div className="d-flex flex-end mt-8">{config.instance ? <EditTemplateBtnAppBridge /> : <EditTemplateBtn />}</div>
      </Card>
    </div>
  );
};
export default memo(CustomTemplate);
