import BoldText from '@/components/BoldText';
import { dateToTimeStamp, formatDate } from '@/helpers';
import useCountry from '@/hooks/useCountry';
import { apiCaller } from '@/redux/query';
import { allAccessSelector } from '@/redux/slice/visitorAnalytics.slice';
import { Card, InlineGrid, SkeletonBodyText } from '@shopify/polaris';
import { BarChart, LineChart, PolarisVizProvider } from '@shopify/polaris-viz';
import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

const VisitorAnalyticsChart = () => {
  const allAccess = useSelector(allAccessSelector);
  const handleCountry = useCountry();
  const { data, isFetching } = apiCaller.useFetchChartAnalyticsQuery({
    endDate: dateToTimeStamp(allAccess.endDate),
    startDate: dateToTimeStamp(allAccess.startDate),
  });

  const topFiveCountries = useMemo(() => {
    if (data) {
      const result = data?.data.top5Countries.map((item) => {
        return {
          key: handleCountry.renderCountry(item.countryCode) || '',
          value: item.total,
        };
      });
      return result;
    }
    return [];
  }, [data, handleCountry]);

  const topFiveStates = useMemo(() => {
    if (data) {
      const result = data?.data.top5States?.map((item) => {
        return {
          key: `${handleCountry.renderCountry(item.countryCode)} - ${item?.state}`,
          value: item.total,
        };
      });
      return result;
    }
    return [];
  }, [data, handleCountry]);

  const dataChart = useMemo(() => {
    if (data) {
      const listKey = ['Low risk IP', 'High risk IP', 'Dangerous IP'];
      const objectChart = {
        totalSafesChart: data.data.safeIpChart.data,
        totalRisksChart: data.data.riskIpChart.data,
        totalVisitorsChart: data.data.dangerousIpChart.data,
      };
      const isDay = dateToTimeStamp(allAccess.endDate) - dateToTimeStamp(allAccess.startDate) > 86400000;
      const categories = data.data.safeIpChart.labels.map((item) => formatDate(item, isDay ? 'D MMM' : 'h:mm a'));
      const colors = ['#008170', '#75C2F6', '#FFB07F'];
      const result = Object.entries(objectChart).map(([_, value], index) => {
        return {
          name: listKey[index],
          color: colors[index],
          data: value.map((item, i) => {
            return {
              value: item,
              key: categories[i],
            };
          }),
        };
      });

      return result;
    }
    return [];
  }, [allAccess.endDate, allAccess.startDate, data]);
  return (
    <InlineGrid columns={{ xl: 3, lg: 3, md: 1, xs: 1, sm: 1 }} gap={'400'}>
      <Card>
        <BoldText>Total visitors</BoldText>
        <PolarisVizProvider>
          <div style={{ height: 300, marginTop: '1rem' }}>
            {isFetching ? <SkeletonBodyText lines={16} /> : <LineChart theme="Light" data={dataChart} />}
          </div>
        </PolarisVizProvider>
      </Card>
      <Card>
        <BoldText>Top 5 visited countries</BoldText>
        <PolarisVizProvider>
          <div style={{ height: 300, marginTop: '1rem' }}>
            {isFetching ? (
              <SkeletonBodyText lines={16} />
            ) : (
              <BarChart
                id="chart"
                theme="Light"
                data={[{ data: topFiveCountries || [], name: 'Total visitor' }]}
                showLegend={false}
              />
            )}
          </div>
        </PolarisVizProvider>
      </Card>

      <Card>
        <BoldText>Top 5 visited states</BoldText>
        <PolarisVizProvider>
          <div style={{ height: 300, marginTop: '1rem' }}>
            {isFetching ? (
              <SkeletonBodyText lines={16} />
            ) : (
              <BarChart
                id="chart"
                theme="Light"
                data={[{ data: topFiveStates || [], name: 'Total visitor' }]}
                showLegend={false}
              />
            )}
          </div>
        </PolarisVizProvider>
      </Card>
    </InlineGrid>
  );
};

export default memo(VisitorAnalyticsChart);
