import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import { useSelector } from 'react-redux';
import { UserPlan } from '../constants/enum';

const UserPlans = () => {
  const dataSettings = useSelector(dataSettingsSelector);

  const userPlanFree = dataSettings?.settings.user.plan === UserPlan.FREE;
  const userPlanPremium = dataSettings?.settings.user.plan === UserPlan.PREMIUM;
  const userPlanEnterprise = dataSettings?.settings.user.plan === UserPlan.ENTERPRISE;

  return {
    userPlanFree,
    userPlanPremium,
    userPlanEnterprise,
  };
};

export default UserPlans;
