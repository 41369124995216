import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { Enum } from '@/constants';
import { ActionType, CriteriaType } from '@/constants/enum';

export interface IGetSettingList {
  search: string;
  type?: string;
  criteria: string;
  priority: string;
  sort: string;
  sortBy: string;
  page: number;
  perPage: string;
  note?: string;
}

export interface IItemBlock {
  id?: number;
  criteria: string | CriteriaType;
  linkRedirect?: string;
  shortUrl?: string;
  priority: string;
  country?: Array<string>;
  state?: Array<string>;
  city?: Array<string>;
  referUrl?: string;
  shortReferUrl?: string;
  ipAddress?: string;
  note?: string;
  ispName?: Array<string>;
  ispCode?: Array<string>;
  specificUrl?: string;
  type: string;
  deviceType?: string;
  osName?: string;
  browserName?: Array<string>;
  isActive: boolean;
  createdAt?: number;
  lastUpdatedAt?: number;
}
export interface IErrorRule {
  criteria?: CriteriaType;
  error?: string | React.ReactElement;
}
interface State {
  inputSearch: {
    blacklist: string;
    whitelist: string;
  };
  isOpenGuide: boolean;
  isDeleteAll: boolean;
  blackListTable: IGetSettingList;
  whiteListTable: IGetSettingList;
  inputSearchBlackList: string;
  inputSearchWhiteList: string;
  setting: IItemBlock;
  settingBackup: IItemBlock;
  inputIsp: string;
  tabSelected: number;
  tabSelectedModal: number;
  perPage: string;
  errorRule: IErrorRule[];
}

// Define the initial state using that type
const initialState: State = {
  errorRule: [],
  isDeleteAll: false,
  isOpenGuide: false,
  tabSelected: 0,
  tabSelectedModal: 0,
  inputSearch: {
    blacklist: '',
    whitelist: '',
  },
  blackListTable: {
    search: '',
    type: '',
    criteria: '',
    page: 1,
    perPage: '10',
    sort: Enum.SortType.DESC,
    sortBy: 'createdAt',
    priority: Enum.ListType.BlackList,
  },
  whiteListTable: {
    priority: Enum.ListType.WhiteList,
    search: '',
    criteria: '',
    sort: Enum.SortType.DESC,
    sortBy: 'createdAt',
    page: 1,
    perPage: '10',
  },
  inputSearchBlackList: '',
  inputSearchWhiteList: '',
  inputIsp: '',
  perPage: '10',
  setting: {
    type: '1',
    criteria: '-1',
    id: undefined,
    linkRedirect: '',
    shortUrl: '',
    referUrl: '',
    specificUrl: '',
    shortReferUrl: '',
    note: '',
    priority: Enum.ListType.BlackList,
    state: [],
    city: [],
    country: [],
    ipAddress: '',
    ispName: [],
    ispCode: [],
    deviceType: '',
    osName: '',
    browserName: [],
    isActive: true,
  },
  settingBackup: {
    type: '1',
    criteria: '-1',
    id: undefined,
    linkRedirect: '',
    shortUrl: '',
    referUrl: '',
    specificUrl: '',
    shortReferUrl: '',
    note: '',
    priority: Enum.ListType.BlackList,
    state: [],
    city: [],
    country: [],
    ipAddress: '',
    ispName: [],
    ispCode: [],
    deviceType: '',
    osName: '',
    browserName: [],
    isActive: true,
  },
};

export const blockListSlice = createSlice({
  name: 'blockList',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleTabSelected: (state, action: PayloadAction<number>) => {
      state.tabSelected = action.payload;
    },
    handleTabSelectedModal: (state, action: PayloadAction<number>) => {
      state.tabSelectedModal = action.payload;
    },
    handleBlackListTable: (state, action: PayloadAction<IGetSettingList>) => {
      state.blackListTable = action.payload;
    },
    handleOpenGuide: (state, action: PayloadAction<boolean>) => {
      state.isOpenGuide = action.payload;
    },
    handleCheckDeleteAll: (state, action: PayloadAction<boolean>) => {
      state.isDeleteAll = action.payload;
    },
    handleWhiteListTable: (state, action: PayloadAction<IGetSettingList>) => {
      state.whiteListTable = action.payload;
    },
    handleInputSearchWhiteList: (state, action: PayloadAction<string>) => {
      state.inputSearchWhiteList = action.payload;
    },
    handleInputSearchBlackList: (state, action: PayloadAction<string>) => {
      state.inputSearchBlackList = action.payload;
    },
    handleInputIsp: (state, action: PayloadAction<string>) => {
      state.inputIsp = action.payload;
    },
    handleInputSearch: (state, action: PayloadAction<{ blacklist: string; whitelist: string }>) => {
      state.inputSearch = action.payload;
    },
    clearSetting: (state) => {
      state.setting = {
        type: ActionType.Block,
        criteria: '-1',
        id: undefined,
        linkRedirect: '',
        shortUrl: '',
        referUrl: '',
        specificUrl: '',
        shortReferUrl: '',
        note: '',
        priority: Enum.ListType.BlackList,
        state: [],
        city: [],
        country: [],
        ipAddress: '',
        ispName: [],
        ispCode: [],
        deviceType: '',
        osName: '',
        browserName: [],
        isActive: true,
      };
      state.inputIsp = '';
    },
    clearInputIsp: (state) => {
      state.inputIsp = '';
    },
    handleSetting: (state, action: PayloadAction<IItemBlock>) => {
      state.setting = action.payload;
    },
    handleSettingBackup: (state, action: PayloadAction<IItemBlock>) => {
      state.settingBackup = action.payload;
    },

    handlePerPage: (state, action: PayloadAction<string>) => {
      state.perPage = action.payload;
    },
    handleErrorRule: (state, action: PayloadAction<IErrorRule[]>) => {
      state.errorRule = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const blackListTableSelector = createSelector(
  (state: RootState) => state.blockList,
  (state) => state.blackListTable,
);
export const tabSelectedSelector = createSelector(
  (state: RootState) => state.blockList,
  (state) => state.tabSelected,
);
export const tabSelectedModalSelector = createSelector(
  (state: RootState) => state.blockList,
  (state) => state.tabSelectedModal,
);
export const settingSelector = createSelector(
  (state: RootState) => state.blockList,
  (state) => state.setting,
);

export const whiteListTableSelector = createSelector(
  (state: RootState) => state.blockList,
  (state) => state.whiteListTable,
);
export const inputSearchBlackListSelector = createSelector(
  (state: RootState) => state.blockList,
  (state) => state.inputSearchBlackList,
);
export const inputSearchWhiteListSelector = createSelector(
  (state: RootState) => state.blockList,
  (state) => state.inputSearchWhiteList,
);

export const inputIspSelector = createSelector(
  (state: RootState) => state.blockList,
  (state) => state.inputIsp,
);

export const openGuideSelector = createSelector(
  (state: RootState) => state.blockList,
  (state) => state.isOpenGuide,
);

export const isDeleteAllSelector = createSelector(
  (state: RootState) => state.blockList,
  (state) => state.isDeleteAll,
);

export const inputSearchSelector = createSelector(
  (state: RootState) => state.blockList,
  (state) => state.inputSearch,
);

export const perPageSelector = createSelector(
  (state: RootState) => state.blockList,
  (state) => state.perPage,
);

export const errorRuleSelector = createSelector(
  (state: RootState) => state.blockList,
  (state) => state.errorRule,
);
export const settingBackupSelector = createSelector(
  (state: RootState) => state.blockList,
  (state) => state.settingBackup,
);

export default blockListSlice;
