import { images } from '@/asset';
import { Enum } from '@/constants';
import { CriteriaType, UserPlan } from './enum';

export const timeReleaseBlockCity = 1702432800000;

export const perPageOptions = [
  {
    label: '10',
    value: '10',
  },
  {
    label: '25',
    value: '25',
  },
  {
    label: '50',
    value: '50',
  },
  {
    label: '100',
    value: '100',
  },
  {
    label: '200',
    value: '200',
  },
  {
    label: '250',
    value: '250',
  },
];

const typeList = [
  {
    label: 'Black list',
    value: Enum.ListType.BlackList,
  },
  {
    label: 'White list',
    value: Enum.ListType.WhiteList,
  },
];

const criteriaOptions = (plan: string) => {
  return [
    {
      label: 'IP Address',
      value: Enum.CriteriaType.IpAddress,
    },
    {
      label: 'IP Address start with',
      value: Enum.CriteriaType.IpAddressStartWith,
    },
    {
      label: 'Country',
      value: Enum.CriteriaType.Country,
    },
    {
      label: 'State/Province',
      value: Enum.CriteriaType.Province,
    },
    {
      label:
        plan === Enum.UserPlan.FREE || plan === Enum.UserPlan.PREMIUM
          ? 'Internet Service Provider (ISP) (Available on Enterprise plan or above)'
          : 'Internet Service Provider (ISP)',
      value: Enum.CriteriaType.ISP,
      disabled: plan === Enum.UserPlan.FREE || plan === Enum.UserPlan.PREMIUM,
    },
  ];
};

const criteriaFilters = [
  {
    label: 'IP Address',
    value: Enum.CriteriaType.IpAddress,
  },
  {
    label: 'IP Address start with',
    value: Enum.CriteriaType.IpAddressStartWith,
  },
  {
    label: 'Country',
    value: Enum.CriteriaType.Country,
  },
  {
    label: 'State/Province',
    value: Enum.CriteriaType.Province,
  },
  {
    label: 'Internet Service Provider (ISP)',
    value: Enum.CriteriaType.ISP,
  },
  {
    label: 'Referral URL',
    value: Enum.CriteriaType.ReferralLink,
  },
  {
    label: 'Specific URL',
    value: Enum.CriteriaType.SpecificPage,
  },
  {
    label: 'IP Ranges',
    value: Enum.CriteriaType.IpRanges,
  },
  {
    label: 'User Agent',
    value: Enum.CriteriaType.UserAgent,
  },
];

const blockTypeOptions = [
  {
    label: 'Block',
    value: Enum.ActionType.Block,
  },
  {
    label: 'Redirect',
    value: Enum.ActionType.Redirect,
  },
];

const listTypeOptions = [
  {
    label: 'Block',
    value: Enum.ActionType.Block,
  },
  {
    label: 'Redirect',
    value: Enum.ActionType.Redirect,
  },
  {
    label: 'Whitelist',
    value: '0',
  },
];

export interface IConfigRules {
  id: CriteriaType;
  description: string;
  src: string;
  condition?: Array<UserPlan>;
  tooltip?: string;
  enable: number;
}

const configRules: Array<IConfigRules> = [
  {
    id: Enum.CriteriaType.IpAddress,
    description: 'IP address',
    src: images.block_ip_address,
    enable: Infinity,
  },
  {
    id: Enum.CriteriaType.IpAddressStartWith,
    description: 'IP start with',
    enable: Infinity,
    src: images.block_ip_range,
  },
  {
    id: Enum.CriteriaType.Country,
    description: 'location',
    enable: Infinity,
    src: images.block_country,
  },
  {
    id: Enum.CriteriaType.Province,
    description: 'state',
    src: images.block_state,
    condition: [Enum.UserPlan.FREE],
    enable: timeReleaseBlockCity,
    tooltip: 'Available on Premium plan or above',
  },
  {
    id: Enum.CriteriaType.UserAgent,
    enable: 0,
    description: 'user agent',
    src: images.userAgent,
    condition: [Enum.UserPlan.FREE],
    tooltip: `Available on Premium plan or above!`,
  },
  {
    id: Enum.CriteriaType.IpRanges,
    description: 'IP ranges',
    enable: 0,
    src: images.block_ip_ranges,
    condition: [Enum.UserPlan.FREE, Enum.UserPlan.PREMIUM],
    tooltip: 'Available on Enterprise plan',
  },
  {
    id: Enum.CriteriaType.SpecificPage,
    description: 'specific URL',
    enable: 0,
    src: images.block_specific_page,
    condition: [Enum.UserPlan.FREE, Enum.UserPlan.PREMIUM],
    tooltip: 'Available on Enterprise plan',
  },
  {
    id: Enum.CriteriaType.ISP,
    description: 'ISP',
    enable: 0,
    src: images.block_isp,
    condition: [Enum.UserPlan.FREE, Enum.UserPlan.PREMIUM],
    tooltip: 'Available on Enterprise plan',
  },
  {
    id: Enum.CriteriaType.ReferralLink,
    description: 'referrer URL',
    enable: 0,
    src: images.referralLink,
    condition: [Enum.UserPlan.FREE, Enum.UserPlan.PREMIUM],
    tooltip: `Available on Enterprise plan`,
  },
];

export const ipOptions = [
  {
    label: 'IP address',
    value: CriteriaType.IpAddress,
  },
  {
    label: 'IP start with',
    value: CriteriaType.IpAddressStartWith,
  },
  {
    label: 'IP ranges',
    value: CriteriaType.IpRanges,
  },
];

export default {
  ipOptions,
  configRules,
  criteriaFilters,
  criteriaOptions,
  blockTypeOptions,
  typeList,
  perPageOptions,
  listTypeOptions,
};
