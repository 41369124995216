import { colors } from '@/constants';
import styled from 'styled-components';

export const ColorPickerStyled = styled.div`
  .rcp-field {
    display: flex;
  }
  .rcp-field-label,
  #rgb,
  #hsv {
    display: none;
  }
  .rcp-fields-floor {
    display: flex;
  }
  .rcp-body {
    background-color: ${colors.gray_background};
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .rcp-field-input {
    color: black;
  }
  #hex {
    border-width: 0.5px;
    max-width: 100%;
  }
  .pre-view {
    margin-bottom: 0.5rem;
    width: 100%;
    height: 3rem;
    border-radius: 0.5rem;
    border: solid 0.5px #333;
  }
`;
