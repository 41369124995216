import options, { perPageOptions } from '@/constants/options';
import blockListSlice, { perPageSelector, whiteListTableSelector } from '@/redux/slice/blockList.slice';
import { ChoiceList } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';

const useFilterWhitelist = () => {
  const dispatch = useDispatch();
  const whiteListTable = useSelector(whiteListTableSelector);
  const perPage = useSelector(perPageSelector);
  const filters = [
    {
      key: 'criteria',
      label: 'Criteria',
      filter: (
        <ChoiceList
          title="Criteria"
          titleHidden
          choices={[{ label: 'All', value: '' }, ...options.criteriaFilters]}
          selected={[whiteListTable.criteria]}
          onChange={(value) =>
            dispatch(
              blockListSlice.actions.handleWhiteListTable({
                ...whiteListTable,
                criteria: value[0],
                page: 1,
              }),
            )
          }
        />
      ),
      shortcut: true,
    },
    {
      key: 'perPage',
      label: 'Per page',
      filter: (
        <ChoiceList
          title="Per page"
          titleHidden
          choices={perPageOptions}
          selected={[perPage]}
          onChange={(value) => dispatch(blockListSlice.actions.handlePerPage(value[0]))}
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [
    {
      onRemove: () =>
        dispatch(
          blockListSlice.actions.handleWhiteListTable({
            ...whiteListTable,
            criteria: '',
            page: 1,
          }),
        ),
      key: 'criteria',
      label: `Criteria: ${options.criteriaFilters.find((option) => option.value === whiteListTable.criteria)?.label || 'All'}`,
    },
    {
      onRemove: () => dispatch(blockListSlice.actions.handlePerPage('10')),
      key: 'perPage',
      label: 'Record per page: ' + perPage,
    },
  ];

  return {
    filters,
    appliedFilters,
  };
};

export default useFilterWhitelist;
