const search = window.location.search.substring(1);
const convertPathname = (pathname: string) => {
  return {
    pathname: `${pathname ? '/' + pathname : ''}`,
    search: '?' + search,
  };
};

export const PATH = {
  DEFAULT: convertPathname('home'),
  HOME: convertPathname('home'),
  BLOCKLIST: convertPathname('blocklist'),
  DASHBOARD: convertPathname('dashboard'),
  SETTINGS: convertPathname('settings'),
  CUSTOMTEMPLATE: convertPathname('custom-template'),
  WELCOME: convertPathname('welcome'),
  BLOCK_PAGE: convertPathname('block-page'),
  FAQS: convertPathname('faqs'),
};
