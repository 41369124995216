import styled from 'styled-components';

export const StyledSortable = styled.div`
  .sort-item {
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    position: relative;
    height: 3.5rem;
    cursor: move;
    .config-rule-img {
      max-width: 100%;
      width: 38px;
      height: 38px;
      background-color: #ffffff;
      position: absolute;
      top: 0.5rem;
      right: 1rem;
      padding: 0.25rem;
    }
  }

  .sort-header {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
  }
`;
