import EmptyState from '@/components/EmptyState';
import RegularText from '@/components/RegularText';
import { Enum } from '@/constants';
import { SortType } from '@/constants/enum';
import { formatDate, secondToTime } from '@/helpers';
import { apiCaller } from '@/redux/query';
import visitorLogSlice, { visitorDetailSelector } from '@/redux/slice/visitorAnalytics.slice';
import { Icon, IndexTable, Modal, Pagination, SkeletonBodyText } from '@shopify/polaris';
import { DesktopMajor, MobileMajor, SelectMinor, SortAscendingMajor, SortDescendingMajor } from '@shopify/polaris-icons';
import { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
interface Props {
  id: string;
  open: boolean;
  handleClose: () => void;
  startDate?: number,
  endDate?: number,
}
const resourceName = {
  singular: 'visitor',
  plural: 'visitors',
};
const VisitorAnalyticsDetail = (props: Props) => {
  const dispatch = useDispatch();
  const visitorsDetailTable = useSelector(visitorDetailSelector);
  const { data, isFetching } = apiCaller.useFetchVisitorDetailQuery(
    {
      ...visitorsDetailTable,
      startDate: props.startDate,
      endDate: props.endDate,
      sort: visitorsDetailTable.sort.toUpperCase(),
      ipAddress: props.id || '',
    },
    { skip: !props.id },
  );
  const items = useMemo(() => {
    return data?.listIp.map((visitorLogItem) => {
      return {
        accessLog: formatDate(visitorLogItem.accessAt),
        referringUrl: visitorLogItem.referringUrl,
        timeOnSite: <div className="w-86">{secondToTime(visitorLogItem.timeOnSite)}</div>,
        device:
          visitorLogItem.device === Enum.DeviceType.DESKTOP ? <Icon source={DesktopMajor} /> : <Icon source={MobileMajor} />,
        browser: visitorLogItem.browserName,
      };
    });
  }, [data?.listIp]);

  const rowMarkup = useMemo(() => {
    return items?.map(({ accessLog, referringUrl, browser, device, timeOnSite }, index) => (
      <IndexTable.Row id={index.toString()} key={index} position={index}>
        <IndexTable.Cell>{accessLog}</IndexTable.Cell>
        <IndexTable.Cell>{referringUrl}</IndexTable.Cell>
        <IndexTable.Cell>{timeOnSite}</IndexTable.Cell>
        <IndexTable.Cell>{device}</IndexTable.Cell>
        <IndexTable.Cell>{browser}</IndexTable.Cell>
      </IndexTable.Row>
    ));
  }, [items]);

  return (
    <Modal open={props.open} onClose={props.handleClose} title={props.id}>
      <IndexTable
        resourceName={resourceName}
        itemCount={items?.length || 0}
        emptyState={isFetching ? <SkeletonBodyText lines={20} /> : <EmptyState />}
        headings={[
          {
            title: (
              <div
                className="d-flex pointer"
                onClick={() => {
                  dispatch(
                    visitorLogSlice.actions.handleVisitorLogModalTable({
                      ...visitorsDetailTable,
                      sort: visitorsDetailTable.sort === SortType.ASC ? SortType.DESC : SortType.ASC,
                      sortBy: 'accessAt',
                    }),
                  );
                }}
              >
                <RegularText>Access log</RegularText>
                <span>
                  {visitorsDetailTable.sortBy !== 'accessAt' ? (
                    <Icon source={SelectMinor} tone="base" />
                  ) : visitorsDetailTable.sort === SortType.ASC ? (
                    <Icon source={SortAscendingMajor} tone="success" />
                  ) : (
                    <Icon source={SortDescendingMajor} tone="success" />
                  )}
                </span>
              </div>
            ),
            id: '5',
          },
          {
            title: <RegularText>Referring URL</RegularText>,
            id: '6',
          },
          {
            title: (
              <div
                className="d-flex pointer"
                onClick={() => {
                  dispatch(
                    visitorLogSlice.actions.handleVisitorLogModalTable({
                      ...visitorsDetailTable,
                      sort: visitorsDetailTable.sort === SortType.ASC ? SortType.DESC : SortType.ASC,
                      sortBy: 'timeOnSite',
                    }),
                  );
                }}
              >
                <RegularText>Time on site</RegularText>
                <span>
                  {visitorsDetailTable.sortBy !== 'timeOnSite' ? (
                    <Icon source={SelectMinor} tone="base" />
                  ) : visitorsDetailTable.sort === SortType.ASC ? (
                    <Icon source={SortAscendingMajor} tone="success" />
                  ) : (
                    <Icon source={SortDescendingMajor} tone="success" />
                  )}
                </span>
              </div>
            ),
            id: '2',
          },
          { title: <RegularText>Device</RegularText>, id: '3' },
          { title: <RegularText>Browser</RegularText>, id: '4' },
        ]}
      >
        {isFetching ? <SkeletonBodyText lines={20} /> : rowMarkup}
      </IndexTable>
      <div className="mt-16 mb-16">
        {data && data.meta.totalItems > 0 ? (
          <Pagination
            label={
              data.meta.totalItems || 0
                ? `Showing ${(visitorsDetailTable.page - 1) * Number(visitorsDetailTable.perPage) + 1} to ${Math.min(
                  visitorsDetailTable.page * Number(visitorsDetailTable.perPage),
                  data?.meta.totalItems || 0,
                )} of ${data?.meta.totalItems || 0} access times`
                : null
            }
            hasPrevious={(data?.meta.currentPage || 0) > 1 && !isFetching}
            onPrevious={() => {
              dispatch(
                visitorLogSlice.actions.handleVisitorLogModalTable({
                  ...visitorsDetailTable,
                  page: visitorsDetailTable.page - 1,
                }),
              );
            }}
            hasNext={
              (data?.meta.currentPage || 0) < Math.ceil((data?.meta.totalItems ?? 0) / Number(visitorsDetailTable.perPage)) &&
              !isFetching
            }
            onNext={() => {
              dispatch(
                visitorLogSlice.actions.handleVisitorLogModalTable({
                  ...visitorsDetailTable,
                  page: visitorsDetailTable.page + 1,
                }),
              );
            }}
          />
        ) : null}
      </div>
    </Modal>
  );
};

export default memo(VisitorAnalyticsDetail);
