import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import AutoCompleteWithTag from '@/components/autoCompleteWithTag';
import { CriteriaType } from '@/constants/enum';
import { validateUrl } from '@/helpers';
import useCountry from '@/hooks/useCountry';
import useErrorRule from '@/pages/block-list/hooks/useErrorRule';
import blockListSlice, { settingSelector } from '@/redux/slice/blockList.slice';
import { TextField } from '@shopify/polaris';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
const Page = () => {
  const dispatch = useDispatch();
  const useError = useErrorRule();
  const blockRuleSelected = useSelector(settingSelector);
  const handleCountry = useCountry({
    countryCode: blockRuleSelected.country ? blockRuleSelected.country[0] : undefined,
    stateName: blockRuleSelected.state ? blockRuleSelected.state[0] : undefined,
  });

  return (
    <div>
      <TextField
        label="Enter a specific URL"
        value={blockRuleSelected.specificUrl}
        onChange={(value) => {
          if (useError.errorSelected?.error) {
            useError.removeErrorRule();
          }
          dispatch(
            blockListSlice.actions.handleSetting({
              ...blockRuleSelected,
              specificUrl: value,
            }),
          );
        }}
        autoComplete="off"
        requiredIndicator
        error={useError.errorSelected?.error}
        helpText={
          <RegularText>Enter the valid specific URL format. Example of a valid URL: https://www.ipblocker.io</RegularText>
        }
        onBlur={() => {
          if (!blockRuleSelected.specificUrl) {
            useError.handleErrorRule('Specific URL is required');
          } else if (!validateUrl(blockRuleSelected.specificUrl)) useError.handleErrorRule('Please enter a valid specific URL');
        }}
      />
      <BoldText>From</BoldText>
      <AutoCompleteWithTag
        options={[...handleCountry.otherCountry, ...handleCountry.listCountry]}
        label="Select country"
        selectedOptions={blockRuleSelected.country || []}
        placeholder="-Country-"
        setSelectedOptions={(value) => {
          if (value.length === 0) {
            useError.handleErrorRule('Country must be added', CriteriaType.Country);
          } else if (useError.getErrorRuleByCriteria(CriteriaType.Country)?.error) {
            useError.removeErrorRule(CriteriaType.Country);
          }
          if (useError.errorSelected?.error) {
            useError.removeErrorRule();
          }
          dispatch(
            blockListSlice.actions.handleSetting({
              ...blockRuleSelected,
              country: value,
            }),
          );
        }}
        allowMultiple={false}
        requiredIndicator
        error={useError.getErrorRuleByCriteria(CriteriaType.Country)?.error}
        onBlur={() => {
          if (blockRuleSelected.country?.length === 0) {
            useError.handleErrorRule('Country must be added', CriteriaType.Country);
          }
        }}
      />
    </div>
  );
};

export default memo(Page);
