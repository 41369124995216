import { colors } from '@/constants';
import { BREAKPOINT } from '@/constants/enum';
import styled from 'styled-components';
export const HomePageStyled = styled.div`
  .homepage-title-container {
    display: flex;
    border-bottom: 1px dotted #e1e3e5;
    width: 100%;
    cursor: pointer;
  }
  .homepage-title-container-no-border {
    display: flex;
    width: 100%;
    cursor: pointer;
  }
  .homepage-title-detail {
    display: flex;
    margin: 8px 0px;
    width: 100%;
    .Polaris-Icon {
      margin: 0px 8px;
    }
  }
  .dashboard-top-container {
    .Polaris-Box {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .action-item-container {
    display: flex;
    justify-content: space-between;
    padding: 0.75rem;
    &:hover {
      background-color: ${colors.bgHover};
    }
  }
  .dashboard-img {
    img {
      height: 200px;
      width: 225px;
      @media screen and (max-width: ${BREAKPOINT.SM}px) {
        height: auto;
        width: auto;
      }
    }
    display: flex;
    justify-content: end;
    padding-right: 2rem;
    @media screen and (max-width: ${BREAKPOINT.SM}px) {
      justify-content: center;
    }
  }
  .synctrack-banner {
    position: relative;
    .synctrack-banner-img {
      width: calc(100% - 0.5rem);
      height: 211px;
      border-radius: 0.5rem;
      cursor: pointer;
      @media screen and (max-width: ${BREAKPOINT.SM}px) {
        width: calc(100%);
        margin-bottom: 1rem;
      }
    }
    .synctrack-banner-dismiss {
      position: absolute;
      right: 0.5rem;
      top: 0rem;
      cursor: pointer;
      @media screen and (max-width: ${BREAKPOINT.SM}px) {
        right: 0.25rem;
      }
    }
  }
  .most-popular-item {
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #ddd;
    button {
      height: fit-content;
      min-width: 50px;
    }
    .mt-4 {
      margin-top: 4px;
    }
    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  .blockify-helper-center-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .blockify-helper-center {
      width: 100%;
      height: 100%;
      .Polaris-ShadowBevel {
        height: 100%;
      }
      .Polaris-Box {
        display: flex;
        flex-direction: column;
        align-items: center;
        .w-100 {
          width: 100%;
        }
      }
      .Polaris-Text--root {
        text-align: center;
      }
    }
    .border-right {
      border-right: 1px solid #ddd;
    }

    .border-bottom {
      padding-bottom: 8px;
      border-bottom: 2px solid #ddd;
    }
    .blockify-helper-center-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 4px 8px;
      cursor: pointer;
      &:hover {
        background-color: #f1f1f1;
      }
      img {
        margin-top: 8px;
        width: 50px;
        height: 50px;
      }
    }
  }
  .Polaris-Page > .Polaris-Box {
    padding-bottom: 8px;
  }
  .inline-grid-br-12 {
    .Polaris-InlineGrid {
      border-radius: 12px;
      overflow: hidden;
    }
  }
`;
