import { PATH } from '@/constants';
import IBlockList from '@/pages/block-list';
import Dashboard from '@/pages/dashboard';
import Settings from '@/pages/settings';
import ModalPreview from '@/pages/settings/components/customTemplate/ModalPreview';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Welcome from '@/pages/welcome';
import useScope from '@/hooks/Scope';
import SkeletonPage from '@/components/SkeletonPage';
import Homepage from '@/pages/homepage';
import BlockPage from '@/pages/block-list/components/BlockPage';
import PricingFAQs from '@/pages/faqs/PricingFAQs';

interface IRouter {
  path: string;
  element: React.ReactNode;
}

const router: Array<IRouter> = [
  {
    path: PATH.DEFAULT.pathname,
    element: <Homepage />,
  },
  {
    path: PATH.BLOCKLIST.pathname,
    element: <IBlockList />,
  },
  {
    path: PATH.DASHBOARD.pathname,
    element: <Dashboard />,
  },
  {
    path: PATH.SETTINGS.pathname,
    element: <Settings />,
  },
  {
    path: PATH.CUSTOMTEMPLATE.pathname,
    element: <ModalPreview />,
  },
  {
    path: PATH.WELCOME.pathname,
    element: <Welcome />,
  },
  {
    path: PATH.BLOCK_PAGE.pathname,
    element: <BlockPage />,
  },
  {
    path: PATH.FAQS.pathname,
    element: <PricingFAQs />,
  },
];
function RenderRouter(): JSX.Element {
  const scope = useScope();

  return scope.isLoading ? (
    <div className="mt-16">
      <SkeletonPage />
    </div>
  ) : scope.isAccessApp ? (
    <Routes>
      {router.map((item: IRouter) => (
        <Route key={item.path} path={item.path} element={item.element} />
      ))}
      <Route path="*" element={<Navigate to={PATH.DEFAULT} replace />} />
    </Routes>
  ) : (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        fontSize: '4rem',
        textAlign: 'center',
        alignContent: 'center',
        lineHeight: '100vh',
      }}
    >
      Permission denied
    </div>
  );
}
export default RenderRouter;
