import styled from 'styled-components';

export const CardStyled = styled.div`
  width: 100%;
  .custom-card-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dfe3e8;
    padding: 12px 16px 7px;
    .custom-card-title {
      font-weight: bold;
      font-size: 16px;
      line-height: 36px;
    }
    .custom-card-btn {
      display: flex;
      align-items: center;
    }
  }

  .custom-card-content {
    padding: 1rem;
    .Polaris-Box {
      height: 100%;
      .indicator-filter-card {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
`;
