import { colors } from '@/constants';
import { BREAKPOINT } from '@/constants/enum';
import styled from 'styled-components';

export const DashboardStyled = styled.div`
  .Polaris-ResourceList__ResourceListWrapper {
    overflow-x: auto;
    .Polaris-ResourceList {
      min-width: 1200px;
    }
  }
  .Polaris-Banner {
    .Polaris-Link--monochrome {
      font-weight: 700;
    }
    .Polaris-ButtonGroup {
      .Polaris-Button {
        color: #FFFFFF;
        background: #1a1a1a;
        box-shadow: 0rem 0.125rem 0rem 0rem rgba(255, 255, 255, 0.2) inset, 0.125rem 0rem 0rem 0rem rgba(255, 255, 255, 0.2) inset, -0.125rem 0rem 0rem 0rem rgba(255, 255, 255, 0.2) inset, 0rem -0.0625rem 0rem 0.0625rem #000 inset, 0rem 0.0625rem 0rem 0rem #000 inset;
        .Polaris-Button__Content {
          font-weight: 650;
        }
      }
    }
  }
  .mt-16 {
    margin-top: 16px;
  }
  .rsm-svg {
    width: fit-content;
    height: fit-content;
  }
  .table-content {
    justify-content: space-between;
  }
  .header {
    .Polaris-Icon {
      margin: 0% 8px;
    }
  }
  nav {
    display: flex;
    justify-content: center;
  }
  .copy-container {
    .Polaris-Icon {
      margin: 0;
      margin-left: 0.5rem;
      /* opacity: 0; */
      display: none;
    }
  }

  .Polaris-IndexFilters__ActionWrap {
    padding-right: 3rem;
    @media screen and (max-width: ${BREAKPOINT.SM}px) {
      padding-right: 3.25rem;
    }
  }
  .table-container {
    position: relative;
  }
  .export-btn {
    position: absolute;
    top: 0.75rem;
    right: 1rem;
  }
  .analytics-items-title {
    display: flex;
    .Polaris-Icon {
      margin: 0;
      margin-right: 0.25rem;
    }
  }
  .chart-container {
    margin: 1rem;
  }
  .analytics-container {
    padding: 1rem;
    background-color: ${colors.gray_background};
  }
  .pointer {
    cursor: pointer;
  }
  .btn-disable {
    width: 20px;
    .Polaris-Icon {
      margin: 0;
    }
  }
  .dashboard-table-container:hover {
    #visitor-analytics-demo,
    #all-access-demo {
      display: flex;
    }
  }
  .allow-table-container:hover {
    #visitor-analytics-demo,
    #all-access-demo {
      display: flex;
    }

  }
  .allow-table-container {
    .Polaris-IndexTable__TableCell--first {
      padding: 8px 8px 8px 12px !important;
      min-width: 38px;
    }
    .Polaris-IndexTable__TableHeading--first {
      padding: 8px 8px 8px 12px !important;
      min-width: 38px;
    }
    .Polaris-IndexTable__TableCell--first .Polaris-IndexTable-Checkbox__TableCellContentContainer {
      display: flex;
    }
    .text-color {
      color: #005BD3;
      cursor: pointer;
    }
  }
  .visitor-heatmap-container {
    position: relative;
  }
  .visitor-heatmap-container:hover {
    #visitor-heatmap-demo {
      display: flex;
    }
  }
  .visitor-analytics-chart-container {
    position: relative;
  }
  .visitor-analytics-chart-container:hover {
    position: relative;
    #visitor-analytics-chart-demo {
      display: flex;
    }
  }
  .analytics-container {
    position: relative;
    .Polaris-Link {
      font-weight: 700;
    }
  }
  .analytics-container:hover {
    position: relative;
    #analytics-demo {
      display: flex;
    }
  }
  .view-demo-container {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffffe6;
    position: absolute;
    z-index: 40;
    padding-top: 5rem;
    align-items: center;
    flex-direction: column;
    display: flex;
  }
  #allAccess,
  #visitorLog {
    .Polaris-Badge {
      background-color: rgb(145 208 255);
    }
  }
`;
