import { Enum } from '@/constants';
import { ISettings } from '@/types/components';
import styled from 'styled-components';

interface Props {
  template: ISettings.CustomTemplate;
  focusId: string;
  css: string;
  smallScreen?: boolean;
}
export const BlockPageStyled = styled.div<Props>`
  position: relative;
  width: 100%;
  .spiner-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    opacity: 0.4;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #blockify---container {
    aspect-ratio: 2 / 1;
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    inset: 0;
    font-size: 16px;
    position: relative;
  }
  ${(props) => props.css}

  #blockify---container:after {
    background-image: ${(props) =>
    props.template?.background?.type === Number(Enum.BackgroundType.Image)
      ? `url(${props.template?.background?.value})`
      : `linear-gradient(to right,${props.template?.background?.colorFrom}, ${props.template?.background?.colorTo})`} !important;
    background-color: ${(props) =>
    Number(props.template?.background?.type) === Number(Enum.BackgroundType.Color)
      ? props.template?.background?.value
      : 'white'} !important;
  }
  #blockify---container {
    background-image: ${(props) =>
    props.template?.background?.type === Number(Enum.BackgroundType.Image)
      ? `url(${props.template?.background?.value})`
      : `linear-gradient(to right,${props.template?.background?.colorFrom}, ${props.template?.background?.colorTo})`} !important;
    background-color: ${(props) =>
    Number(props.template?.background?.type) === Number(Enum.BackgroundType.Color)
      ? props.template?.background?.value
      : 'white'} !important;
  }
  #blockify-block-superTitle {
    color: ${(props) => props.template?.superTitle?.color} !important;
  }
  #blockify-block-title {
    color: ${(props) => props.template?.title?.color} !important;
  }
  #blockify-block-description {
    color: ${(props) => props.template?.description?.color} !important;
  }
  label {
    display: block;
    line-height: normal;
  }
  .watermark-container {
    position: relative;
    bottom: 1.5rem;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      margin: 0 4px;
    }
  }

  label[for=${(props) => props.focusId}] {
    border: 1px dotted #005bd3;
  }
  ${(props) =>
    props.smallScreen
      ? `
      #blockify---container {
        font-size : 24px;
      }
      .Polaris-Link {
        font-size : 36px
      }
      .watermark-container {
        div {
        font-size : 120%

        }
      }
      .watermark-container img {
        height :  48px;
        width : 48px;
        magin : 0 5px
      }
`
      : ''}
`;
