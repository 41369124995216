import enTranslations from '@shopify/polaris/locales/en.json';
import RenderRouter from '@/routes';
import { AppProvider } from '@shopify/polaris';
import { useEffect } from 'react';
import './App.css';
import { hotjar } from 'react-hotjar';
import mixpanel from 'mixpanel-browser';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Crisp } from 'crisp-sdk-web';
import Review from './pages/Review';
import { config } from '@/config';
import useScope from '@/hooks/Scope';
import { useDispatch } from 'react-redux';
import { apiCaller } from './redux/query';
import slice from './redux/slice';

function App() {
  const scope = useScope();
  const dispatch = useDispatch();
  // const history = createBrowserHistory();
  // const location = useLocation();

  const fetchDataSettings = apiCaller.useGetGeneralDetailQuery(config?.shop);

  useEffect(() => {
    const endPoint = process.env.REACT_APP_API_END_POINT;
    const script = document.createElement('script');
    script.src = `${endPoint}file/crisp`;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    mixpanel.init(`${process.env.REACT_APP_MIXPANEL_ID}`);
    // Set this to a unique identifier for the user performing the event.
    mixpanel.identify(`${config.instance}`);
    if (!scope.isViewOnly) mixpanel.track('Page View');
    hotjar.initialize(Number(process.env.REACT_APP_HOTJAR_ID), 6);
  }, [scope.isViewOnly]);

  useEffect(() => {
    if (fetchDataSettings.data) {
      dispatch(
        slice.dataSetting.actions.handleSettings({
          settings: fetchDataSettings.data.settings,
          summaryStats: fetchDataSettings.data.summaryStats,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDataSettings.data]);

  useEffect(() => {
    dispatch(slice.dataSetting.actions.handleLoading(fetchDataSettings.isFetching));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDataSettings.isFetching]);

  return (
    <AppProvider i18n={enTranslations}>
      {/* Nếu như bạn đã có AppBridgeProvider config, hãy bỏ cmt ClientRouter , RoutePropagator,history và location */}
      {/* <ClientRouter history={history} /> */}
      {/* <RoutePropagator location={location} /> */}
      <RenderRouter />
      <Review />
    </AppProvider>
  );
}

export default App;
