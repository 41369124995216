/* eslint-disable jsx-a11y/img-redundant-alt */
import { images } from '@/asset';
import { config } from '@/config';
import { Enum } from '@/constants';
import { apiCaller } from '@/redux/query';
import { Modal, Button, Text } from '@shopify/polaris';
import { useEffect, useState } from 'react';

function Review() {
  const setting = apiCaller.useGetGeneralDetailQuery(config.shop);
  const [updateReview] = apiCaller.useUpdateReviewMutation();
  const [createReview] = apiCaller.useCreateReviewMutation();
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => {
    createReview({
      type: Enum.ReviewType.REJECT,
      content: 'Reject',
    }).then((response) => {
      if ('data' in response) return response.data;
    });
    updateReview(true).then((response) => {
      if ('data' in response) return setIsOpen(false);
    });
  };
  const handleReview = () => {
    createReview({
      type: Enum.ReviewType.ACCEPT,
      content: 'Accept',
    }).then((response) => {
      if ('data' in response) return response.data;
    });
    updateReview(true).then((response) => {
      if ('data' in response) return setIsOpen(false);
    });
  };
  useEffect(() => {
    if (setting.data && !setting.data.settings.hasReview) {
      setIsOpen(!!setting.data?.settings?.totalBlock);
    }
  }, [setting.data]);

  return (
    <Modal title="" titleHidden open={isOpen} onClose={handleClose}>
      <Modal.Section>
        <Text alignment="center" as="h3" variant="headingMd">
          Hurray! You have used Blockify!
        </Text>
        <div>
          <div style={{ display: 'flex', justifyContent: 'center', margin: '32px 0px' }}>
            <img src={images.review} alt="image" />
          </div>
          <Text breakWord alignment="center" as="p" tone="subdued" variant="bodyMd">
            Over the last week, Blockify has blocked {setting.data?.settings?.totalBlock} unwanted visits. Let others know how our
            app is working for you by leaving a review!
          </Text>
          <div style={{ marginTop: '32px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Button size="medium" onClick={handleReview} variant="primary">
              Leave a review
            </Button>
          </div>
        </div>
      </Modal.Section>
    </Modal>
  );
}

export default Review;
