import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
// Define the initial state using that type
const initialState = {
  bannerReviewSetUpApp: true,
  bannerAnalytics: true,
  bannerSupportAnalytics: true,
  synctrackBanner: true,
  showBannerWaterMark: true,
  limitAccessBanner: true,
  bannerCustomCSS: true,
  bannerActiveApp: true,
  bannerNoticeFreePlan: true,
};

const bannerSlice = createSlice({
  name: 'banner',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleShowBannerReviewSetUpApp: (state, action: PayloadAction<boolean>) => {
      state.bannerReviewSetUpApp = action.payload;
    },
    handleShowBannerVisitorAnalytics: (state, action: PayloadAction<boolean>) => {
      state.bannerAnalytics = action.payload;
    },
    handleShowBannerSupportVisitorAnalytics: (state, action: PayloadAction<boolean>) => {
      state.bannerSupportAnalytics = action.payload;
    },
    handleSynctrackBanner: (state, action: PayloadAction<boolean>) => {
      state.synctrackBanner = action.payload;
    },
    handleShowLimitAccessBanner: (state, action: PayloadAction<boolean>) => {
      state.limitAccessBanner = action.payload;
    },
    handlesShowBannerWaterMark: (state, action: PayloadAction<boolean>) => {
      state.showBannerWaterMark = action.payload;
    },
    handleBannerCustomCSS: (state, action: PayloadAction<boolean>) => {
      state.bannerCustomCSS = action.payload;
    },
    handleBannerActiveApp: (state, action: PayloadAction<boolean>) => {
      state.bannerActiveApp = action.payload;
    },
    handleBannerNoticeFreePlan: (state, action: PayloadAction<boolean>) => {
      state.bannerNoticeFreePlan = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const bannerReviewSetUpAppSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.bannerReviewSetUpApp,
);

export const bannerAnalyticsSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.bannerAnalytics,
);
export const bannerSupportAnalyticsSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.bannerSupportAnalytics,
);

export const synctrackBannerSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.synctrackBanner,
);

export const limitAccessBannerSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.limitAccessBanner,
);

export const showBannerWaterMarkSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.showBannerWaterMark,
);

export const bannerCustomCSSSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.bannerCustomCSS,
);

export const bannerActiveAppSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.bannerActiveApp,
);
export const bannerNoticeFreePlanSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state.bannerNoticeFreePlan,
);

export default bannerSlice;
