import { memo, useState } from 'react';

import { ISettings } from '@/types/components';
import { DropZone, InlineError } from '@shopify/polaris';

const maxSize = 2000000;
function CustomImage({ ...props }: ISettings.CustomImage) {
  const [error, setError] = useState('');
  const handleDropZoneDrop = async (_dropFiles: File[], acceptedFiles: File[], _rejectedFiles: File[]) => {
    if (acceptedFiles.length > 0 && validImageTypes.includes(acceptedFiles[0].type)) {
      setError('');
      props.handleUpload(acceptedFiles[0], window.URL.createObjectURL(acceptedFiles[0]));
    }
  };

  const validateFile = (file: any) => {
    props.handleSaved();
    if (file.size > maxSize) {
      setError('File size too large (more than 2MB)');
    }
    if (!validImageTypes.includes(file.type)) {
      setError('Unsupported file format. We only support formats: JPG, JPEG, PNG');
    }
    return file.size < maxSize && validImageTypes.includes(file.type);
  };

  const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
  const actionHint = 'Supported formats: JPG, JPEG, PNG\nMaximum up to 2MB';
  const fileUpload = <DropZone.FileUpload actionHint={actionHint} actionTitle="Choose file" />;

  return (
    <div>
      <DropZone id={props.id} customValidator={validateFile} label="" labelHidden onDrop={handleDropZoneDrop} variableHeight>
        {fileUpload}
      </DropZone>
      {!!error && !props.isSaved ? <InlineError message={error} fieldID={props.id} /> : null}
    </div>
  );
}

export default memo(CustomImage);
