import React from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store, { persistor } from '@/redux/store';
// import { Provider as AppBridgeProvider } from '@shopify/app-bridge-react';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import reportWebVitals from './reportWebVitals';
import '@shopify/polaris/build/esm/styles.css';
import '@shopify/polaris-viz/build/esm/styles.css';
import './index.css';

function ErrorFallback({ error, resetErrorBoundary }: any) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

// const configApp = {
//   apiKey: 'YOUR_API_KEY',
//   host: 'YOUR_HOST',
//   forceRedirect: false,
// };

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {}}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {/* Nếu như bạn đã có AppBridgeProvider config, hãy bỏ cmt <AppBridgeProvider , configApp */}
          {/* <AppBridgeProvider config={configApp}> */}
          <BrowserRouter>
            <App />
          </BrowserRouter>
          {/* </AppBridgeProvider> */}
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
