import { images } from '@/asset';
import { config } from '@/config';
import { apiCaller } from '@/redux/query';
import { Text } from '@shopify/polaris';

const StepTwo = () => {
    const { data } = apiCaller.useGetGeneralDetailQuery(config.shop);
    const handleOpenPublishSite = () => {
        window.open(data?.settings.urlSiteEditor);
    };
    return (
        <div className="step-two">
            <Text as="h3" variant="heading2xl">
                Don't forget to publish your site
            </Text>
            <div className="mt-16">
                <Text as="p" variant="bodyMd">
                    For Blockify to <b>works seamlessly</b> with your site, make sure to <b>publish</b> in the{' '}
                    <b className='btn-link' onClick={handleOpenPublishSite}>
                        Site Editor
                    </b>
                    .
                </Text>
                <Text as="p" variant="bodyMd">
                    If you have already published your site, please skip this step.
                </Text>
            </div>
            <img src={images.openTheme} alt="_blank" loading="lazy" />
        </div>
    );
};

export default StepTwo;
