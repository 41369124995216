import EmptyState from '@/components/EmptyState';
import RegularText from '@/components/RegularText';
import { Enum } from '@/constants';
import { StatusIpLog } from '@/constants/enum';
import { dateToTimeStamp, formatDate } from '@/helpers';
import useCountry from '@/hooks/useCountry';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { blockedDetailSelector } from '@/redux/slice/dashboard.slice';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import visitorLogSlice, {
  allAccessDetailSelector,
  allAccessSelector,
  perPageSelector,
  visitorDetailSelector,
} from '@/redux/slice/visitorAnalytics.slice';
import { Badge, BadgeProps, IndexTable, Link, Pagination, SkeletonBodyText, Text, Tooltip } from '@shopify/polaris';
import { memo, useEffect, useMemo, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useDispatch, useSelector } from 'react-redux';
import BlockedHistoryDetail from '../Detail/Blocked';
import VisitorsDetail from '../Detail/Visitors';
import TooltipUpdateHigherPlan from './TooltipUpdateHigherPlan';

const badgeRiskConfig: Array<{ score: Array<number>; tone: BadgeProps['tone']; tooltip: string }> = [
  {
    score: [-1, Enum.RiskLevel.Safe],
    tone: 'success',
    tooltip: 'Low risk IP',
  },
  {
    score: [Enum.RiskLevel.Safe, Enum.RiskLevel.Risky],
    tone: 'warning',
    tooltip: 'High risk IP',
  },
  {
    score: [Enum.RiskLevel.Risky, Enum.RiskLevel.Dangerous],
    tone: 'critical',
    tooltip: 'Dangerous IP',
  },
];

const AllAccessTable = (): JSX.Element => {
  const dispatch = useDispatch();
  const perPage = useSelector(perPageSelector);
  const dataSettings = useSelector(dataSettingsSelector);
  const allAccessTable = useSelector(allAccessSelector);
  const allAccessDetail = useSelector(allAccessDetailSelector);
  const visitorsDetailTable = useSelector(visitorDetailSelector);
  const blockedDetailTable = useSelector(blockedDetailSelector);
  const handleCountry = useCountry();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const { data, isFetching } = apiCaller.useFetchListAccessAllQuery({
    ...allAccessTable,
    sort: allAccessTable.sort.toUpperCase(),
    endDate: dateToTimeStamp(allAccessTable.endDate),
    startDate: dateToTimeStamp(allAccessTable.startDate),
    perPage,
  });
  const [openDetail, setOpenDetail] = useState({
    open: false,
    id: '',
    type: '',
  });

  const resourceName = {
    singular: 'visitor',
    plural: 'visitors',
  };

  useEffect(() => {
    if (data && dataSettings?.settings.user.plan === Enum.UserPlan.FREE) {
      dispatch(
        slice.visitorAnalyticsSlice.actions.handleAllAccessDetail({
          ...allAccessDetail,
          perPage: '10',
          page: 1,
          sort: Enum.SortType.DESC,
          sortBy: 'accessAt',
          startDate: data?.meta.startDate,
          endDate: data?.meta.endDate,
        }),
      );
    } else {
      dispatch(
        slice.visitorAnalyticsSlice.actions.handleAllAccessDetail({
          ...allAccessDetail,
          perPage: '10',
          page: 1,
          sort: Enum.SortType.DESC,
          sortBy: 'accessAt',
          startDate: allAccessTable.startDate,
          endDate: allAccessTable.endDate,
        }),
      );
    }
    // eslint-disable-next-line
  }, [dispatch, dataSettings?.settings.user.plan, data, allAccessTable.endDate, allAccessTable.startDate])

  const items = useMemo(() => {
    return data?.listIp.map((accessItem) => {
      const riskScore = accessItem.risk;

      let badgeRisk;
      if (riskScore === null) {
        badgeRisk = undefined;
      } else {
        badgeRisk = badgeRiskConfig.find((item) => {
          return item.score[0] < riskScore && riskScore <= item.score[1];
        });
      }

      return {
        id: accessItem.id,
        ipBlocked: (
          <RegularText>
            {accessItem.ip}
            <br />
            <Text variant="bodySm" as="span">
              {accessItem.ipv4 ? `IPv4: ${accessItem.ipv4}` : null}
            </Text>
          </RegularText>
        ),
        country: (
          <Tooltip dismissOnMouseOut content={handleCountry.renderCountry(accessItem.countryCode)}>
            <ReactCountryFlag svg className="emojiFlag" countryCode={accessItem.countryCode} />
          </Tooltip>
        ),
        city: <RegularText>{[accessItem.regionName, accessItem.cityName].filter((item) => !!item).join(' - ')}</RegularText>,
        totalAccess: (
          <p style={{ width: '4rem', textAlign: 'end' }}>
            <RegularText>
              <Link
                removeUnderline
                onClick={() => {
                  setOpenDetail({
                    open: true,
                    id: accessItem.ip,
                    type: accessItem.listOf,
                  });
                  dispatch(
                    slice.visitorAnalyticsSlice.actions.handleAllAccessDetail({
                      ...allAccessDetail,
                      perPage: '10',
                      page: 1,
                      sort: Enum.SortType.DESC,
                      sortBy: 'accessAt',
                    }),
                  );
                }}
              >
                {accessItem.totalAccess}
              </Link>
            </RegularText>
          </p>
        ),
        lastSeenOn: <RegularText>{formatDate(accessItem.lastSeenOn)}</RegularText>,
        ispName:
          dataSettings?.settings.user.plan === Enum.UserPlan.FREE ? (
            '---'
          ) : (
            <RegularText>{accessItem.provider || '---'}</RegularText>
          ),
        // zipCode: <RegularText>{accessItem.zipCode || '---'}</RegularText>,
        risk: badgeRisk?.tooltip ? (
          dataSettings?.settings.user.plan === Enum.UserPlan.FREE ? (
            '---'
          ) : (
            <Tooltip content={badgeRisk?.tooltip}>
              <Badge tone={badgeRisk?.tone}>{riskScore === null ? 'N/A' : String(riskScore)}</Badge>
            </Tooltip>
          )
        ) : (
          <Badge tone={badgeRisk?.tone}>{riskScore === null ? 'N/A' : String(riskScore)}</Badge>
        ),
        isVpn: dataSettings?.settings.user.plan === Enum.UserPlan.FREE ? '---'
          : (
            <Badge tone={accessItem.isVpn === Enum.VPN.TRUE ? 'warning' : 'success'}>{accessItem.isVpn === Enum.VPN.TRUE ? 'Yes' : 'No'}</Badge>
          ),
        typeList:
          accessItem.listOf === StatusIpLog.BLOCK ? (
            <Badge tone="critical">Blocked</Badge>
          ) : (
            <Badge tone="success">Allowed</Badge>
          ),
      };
    });
  }, [allAccessDetail, data?.listIp, dataSettings?.settings.user.plan, dispatch, handleCountry]);

  const rowMarkup = useMemo(() => {
    return items?.map(({ id, ipBlocked, city, country, lastSeenOn, risk, totalAccess, ispName, isVpn, typeList }, index) => (
      <IndexTable.Row id={id} key={id} position={index}>
        <IndexTable.Cell>{ipBlocked}</IndexTable.Cell>
        <IndexTable.Cell>{country}</IndexTable.Cell>
        <IndexTable.Cell>{city}</IndexTable.Cell>
        <IndexTable.Cell>{totalAccess}</IndexTable.Cell>
        <IndexTable.Cell>{lastSeenOn}</IndexTable.Cell>
        <IndexTable.Cell>{ispName}</IndexTable.Cell>
        {/* <IndexTable.Cell>{zipCode}</IndexTable.Cell> */}
        <IndexTable.Cell id="risk-score">
          <div className="w-57">{risk}</div>
        </IndexTable.Cell>
        <IndexTable.Cell>{isVpn}</IndexTable.Cell>
        <IndexTable.Cell>{typeList}</IndexTable.Cell>
      </IndexTable.Row>
    ));
  }, [items]);

  return (
    <div className="dashboard-table-container">
      <IndexTable
        resourceName={resourceName}
        itemCount={items?.length || 0}
        emptyState={isFetching ? <SkeletonBodyText lines={20} /> : <EmptyState />}
        headings={[
          { title: <RegularText>IP address</RegularText>, id: '1' },
          { title: <RegularText>Country</RegularText>, id: '2' },
          { title: <RegularText>Location</RegularText>, id: '3' },
          { title: <RegularText>Total access</RegularText>, id: '4' },
          {
            title: <RegularText>Last seen on</RegularText>,
            id: '5',
          },
          {
            title: (
              <div className="d-flex">
                <RegularText>Internet provider</RegularText>
                {dataSettings?.settings.user.plan === Enum.UserPlan.FREE ? <TooltipUpdateHigherPlan /> : null}
              </div>
            ),
            id: '6',
          },
          // {
          //   title: <RegularText>Zip code</RegularText>,
          //   id: '7',
          // },
          {
            title: (
              <div className="d-flex va-risk-score">
                <RegularText>Risk score</RegularText>
                {dataSettings?.settings.user.plan === Enum.UserPlan.FREE ? (
                  <TooltipUpdateHigherPlan url="https://docs.ipblocker.io/getting-started/visitor-analytics#risk-score" />
                ) : null}
              </div>
            ),
            id: 'risk-score-header',
          },
          {
            title: (
              <div className="d-flex">
                <RegularText>VPN/Proxy</RegularText>
                {dataSettings?.settings.user.plan === Enum.UserPlan.FREE ? <TooltipUpdateHigherPlan /> : null}
              </div>
            ),
            id: '8',
          },
          { title: <RegularText>Type list</RegularText>, id: '9' },
        ]}
      >
        {isFetching ? <SkeletonBodyText lines={20} /> : rowMarkup}
      </IndexTable>
      <div className="mt-16 mb-16 table-pagination">
        {data && data?.meta.totalItems > 0 ? (
          <Pagination
            label={
              data?.meta.totalItems
                ? `Showing ${(allAccessTable.page - 1) * Number(perPage) + 1} to ${Math.min(
                  allAccessTable.page * Number(perPage),
                  data?.meta.totalItems,
                )} of ${data?.meta.totalItems} visitors`
                : null
            }
            hasPrevious={data && data?.meta?.currentPage > 1 && !isFetching}
            onPrevious={() => {
              dispatch(
                visitorLogSlice.actions.handleAllAccessTable({
                  ...allAccessTable,
                  page: allAccessTable.page - 1,
                }),
              );
            }}
            hasNext={data && data?.meta?.currentPage < Math.ceil((data?.meta?.totalItems ?? 0) / Number(perPage)) && !isFetching}
            onNext={() => {
              dispatch(
                visitorLogSlice.actions.handleAllAccessTable({
                  ...allAccessTable,
                  page: allAccessTable.page + 1,
                }),
              );
            }}
          />
        ) : null}
      </div>
      {openDetail.type === StatusIpLog.ALLOW ? (
        <VisitorsDetail
          open={openDetail.open}
          id={openDetail.id}
          handleClose={() => {
            setOpenDetail({
              open: false, id: '', type: ''
            });
            dispatch(
              visitorLogSlice.actions.handleVisitorLogModalTable({
                ...visitorsDetailTable,
                startDate: allAccessDetail.startDate,
                endDate: allAccessDetail.endDate,
                page: 1,
              }),
            );
          }}
          startDate={Number(allAccessDetail.startDate)}
          endDate={Number(allAccessDetail.endDate)}
        />
      ) : (
        <BlockedHistoryDetail
          open={openDetail.open}
          id={openDetail.id}
          handleClose={() => {
            setOpenDetail({
              open: false, id: '', type: ''
            });
            dispatch(
              slice.dashboardSlice.actions.handleBlockDetail({
                ...blockedDetailTable,
                startDate: allAccessDetail.startDate,
                endDate: allAccessDetail.endDate,
                page: 1,
              }),
            );
          }}
          startDate={Number(allAccessDetail.startDate)}
          endDate={Number(allAccessDetail.endDate)}
        />
      )}
    </div>
  );
};

export default memo(AllAccessTable);
