import BoldText from '@/components/BoldText';
import CustomLayout from '@/components/layout';
import { BREAKPOINT } from '@/constants/enum';
import slice from '@/redux/slice';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import { selectedSelector } from '@/redux/slice/settings.slice';
import { Button, Icon, InlineGrid, OptionList } from '@shopify/polaris';
import { CircleDisabledMajor, ComposeMajor, ListMinor, SecureMajor, SettingsMinor, ViewMajor } from '@shopify/polaris-icons';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import BlockerAndRedirector from './components/BlockerAndRedirector';
import ConfigRule from './components/ConfigRule';
import ContentProtection from './components/contentProtection';
import CustomTemplate from './components/customTemplate';
import GeneralSettings from './components/GeneralSettings';
import { SettingsStyled } from './styled';
const configItems = [
  {
    value: 'general',
    content: <GeneralSettings />,
  },
  {
    value: 'blocker',
    content: <BlockerAndRedirector />,
  },
  {
    value: 'priorityRule',
    content: <ConfigRule />,
  },
  {
    value: 'content',
    content: <ContentProtection />,
  },
  {
    value: 'template',
    content: <CustomTemplate />,
  }
];
const options = [
  {
    value: 'general',
    label: (
      <div className="settings-title">
        <Icon source={SettingsMinor} tone="base" />
        <BoldText>General settings</BoldText>
      </div>
    ),
  },
  {
    value: 'priorityRule',
    label: (
      <div className="settings-title">
        <Icon source={ListMinor} tone="base" />
        <BoldText>Priority rules</BoldText>
      </div>
    ),
  },
  {
    value: 'blocker',
    label: (
      <div className="settings-title">
        <Icon source={CircleDisabledMajor} tone="base" />
        <BoldText>Bot killer</BoldText>
      </div>
    ),
  },
  {
    value: 'content',
    label: (
      <div className="settings-title">
        <Icon source={SecureMajor} tone="base" />
        <BoldText>Content protection</BoldText>
      </div>
    ),
  },
  {
    value: 'template',
    label: (
      <div className="settings-title">
        <Icon source={ComposeMajor} tone="base" />
        <BoldText>Blocking template</BoldText>
      </div>
    ),
  }
];
const SecondaryAction = (): JSX.Element => {
  const dataSettings = useSelector(dataSettingsSelector);
  const handleOpenPreview = () => {
    window.open(dataSettings?.settings.testBlockUrl);
  };
  return (
    <Button
      onClick={handleOpenPreview}
      icon={ViewMajor}
    >
      Preview
    </Button>
  );
};

const Settings = (): JSX.Element => {
  const dispatch = useDispatch();
  const selected = useSelector(selectedSelector);
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT.SM });
  return (
    <SettingsStyled>
      <CustomLayout
        layoutProps={{
          title: 'Settings',
          secondaryActions: selected[0] === 'custom' ? <SecondaryAction /> : undefined,
        }}
      >
        <InlineGrid columns={isMobile ? 1 : ['oneThird', 'twoThirds']} gap={'400'}>
          <OptionList
            title=""
            onChange={(value: Array<string>) => {
              dispatch(slice.settingsSlice.actions.handleSelected(value));
            }}
            options={options}
            selected={selected}
          />
          {configItems.find((item) => item.value === selected[0])?.content}
        </InlineGrid>
      </CustomLayout>
    </SettingsStyled>
  );
};

export default memo(Settings);
