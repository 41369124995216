/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { memo } from 'react';
import { Text } from '@shopify/polaris';
import { images } from '@/asset';

const StepOne = () => {
    return (
        <div className="tab-pane">
            <Text as="h3" variant="heading2xl">
                Welcome to Blockify!
            </Text>
            <div>
                <div className="image-center">
                    <img loading="lazy" src={images.illustration} alt="Responsive image" />
                </div>
                <div className="welcome-question">
                    <Text as="h3" variant="headingXl">
                        Ready to protect your website?
                    </Text>
                </div>
                <div className="mt-8">
                    <Text as="h3" variant="bodyMd">
                        We’ve created this quick start guide to help you get ready in just a few clicks!
                    </Text>
                </div>
            </div>
        </div>
    );
};

export default memo(StepOne);
