import RegularText from '@/components/RegularText';
import { config } from '@/config';
import { Enum } from '@/constants';
import { UserPlan } from '@/constants/enum';
import { apiCaller } from '@/redux/query';
import { settingSelector } from '@/redux/slice/blockList.slice';
import { Link } from '@shopify/polaris';
import mixpanel from 'mixpanel-browser';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

const numberRules = 6;

const useLimitRule = () => {
  const blockRuleSelected = useSelector(settingSelector);
  const { data } = apiCaller.useGetGeneralDetailQuery(config.shop);
  const setting = useSelector(settingSelector);
  const countNumberOfRule = useCallback(() => {
    const ruleBlockSummary = apiCaller.useRulesSummaryQuery(
      {
        type: setting.type !== '0' ? setting.type : undefined,
        priority: setting.priority,
        criteria: setting.criteria,
      },
      { skip: data?.settings.user.plan !== UserPlan.FREE },
    );
    return ruleBlockSummary.data && numberRules - ruleBlockSummary.data.totalItems > 0
      ? numberRules - ruleBlockSummary.data.totalItems
      : 0;
  }, [setting.criteria, data?.settings.user.plan, setting.priority, setting.type]);
  const Component = () =>
    data && data?.settings.user.plan === Enum.UserPlan.FREE ? (
      <div className="mt-8">
        <RegularText>
          You have only <b>{countNumberOfRule()}</b> rule in the Free plan.{' '}
          <Link
            onClick={() => {
              mixpanel.track('W_block_redirect_upgrade');
            }}
            external
            url={`https://www.wix.com/apps/upgrade/${process.env.REACT_APP_APP_ID}?appInstanceId=${data?.settings.instanceId}`}
            removeUnderline
          >
            Upgrade now&nbsp;
          </Link>
          to <b>unlock unlimited</b> rules and more features!
        </RegularText>
      </div>
    ) : null;
  return {
    component: Component(),
    count: data?.settings.user.plan === Enum.UserPlan.FREE && !blockRuleSelected.id ? countNumberOfRule() : Infinity,
  };
};

export default useLimitRule;
