const getUrlParams = (key: string) => {
  const params = new URLSearchParams(window.location.search);
  const result = params.get(key);
  return result || undefined;
};

const getUrlParameter = (sParam: string): string | null => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return urlSearchParams.get(sParam);
};

export const config = {
  instance: getUrlParams('instance'),
  shop: getUrlParams('shop'),
  urlParams: getUrlParameter('urlParams'),
  source: getUrlParameter('source'),
  token: getUrlParameter('token'),
  site: decodeURIComponent(getUrlParams('siteUrl') || '').match(/\/\/www\.([^.]+)\./),
};
