import EmptyState from '@/components/EmptyState';
import RegularText from '@/components/RegularText';
import { Enum } from '@/constants';
import { dateToTimeStamp, formatDate } from '@/helpers';
import useCountry from '@/hooks/useCountry';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import dashboardSlice, { blockHistorySelector, blockedDetailSelector } from '@/redux/slice/dashboard.slice';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import { allAccessDetailSelector, allAccessSelector, perPageSelector } from '@/redux/slice/visitorAnalytics.slice';
import { Badge, BadgeProps, IndexTable, Link, Pagination, SkeletonBodyText, Text, Tooltip } from '@shopify/polaris';
import { memo, useMemo, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useDispatch, useSelector } from 'react-redux';
import BlockedHistoryDetail from '../Detail/Blocked';
import TooltipUpdateHigherPlan from './TooltipUpdateHigherPlan';
const badgeRiskConfig: Array<{ score: Array<number>; tone: BadgeProps['tone']; tooltip: string }> = [
  {
    score: [-1, Enum.RiskLevel.Safe],
    tone: 'success',
    tooltip: 'Low risk IP',
  },
  {
    score: [Enum.RiskLevel.Safe, Enum.RiskLevel.Risky],
    tone: 'warning',
    tooltip: 'High risk IP',
  },
  {
    score: [Enum.RiskLevel.Risky, Enum.RiskLevel.Dangerous],
    tone: 'critical',
    tooltip: 'Dangerous IP',
  },
];

const TableBlocked = (): JSX.Element => {
  const dispatch = useDispatch();
  const perPage = useSelector(perPageSelector);
  const dataSettings = useSelector(dataSettingsSelector);
  const allAccess = useSelector(allAccessSelector);
  const blockHistoryTable = useSelector(blockHistorySelector);
  const blockDetailTable = useSelector(blockedDetailSelector);
  const allAccessDetail = useSelector(allAccessDetailSelector);

  const handleCountry = useCountry();
  const [openDetail, setOpenDetail] = useState({
    open: false,
    id: '',
  });

  const { data, isFetching } = apiCaller.useFetchVisitorBlockListQuery({
    ...blockHistoryTable,
    sort: blockHistoryTable.sort.toUpperCase(),
    endDate: dateToTimeStamp(allAccess.endDate),
    startDate: dateToTimeStamp(allAccess.startDate),
    perPage,
  });

  const resourceName = {
    singular: 'blocked',
    plural: 'blocked',
  };
  const items = useMemo(() => {
    return data?.listIp.map((blockedItem) => {
      let badgeRisk;
      const riskScore = blockedItem.risk;
      if (riskScore === null) {
        badgeRisk = undefined;
      } else {
        badgeRisk = badgeRiskConfig.find((item) => {
          return item.score[0] < riskScore && riskScore <= item.score[1];
        });
      }

      return {
        id: blockedItem.id,
        ipBlocked: (
          <RegularText>
            {blockedItem.ip}
            <br />
            <Text variant="bodySm" as="span">
              {blockedItem.ipv4 ? `IPv4: ${blockedItem.ipv4}` : null}
            </Text>
          </RegularText>
        ),
        country: (
          <Tooltip dismissOnMouseOut content={handleCountry.renderCountry(blockedItem.countryCode)}>
            <ReactCountryFlag svg className="emojiFlag" countryCode={blockedItem.countryCode} />
          </Tooltip>
        ),
        city: <RegularText>{[blockedItem.regionName, blockedItem.cityName].filter((item) => !!item).join(' - ')}</RegularText>,
        totalAccess: (
          <p style={{ width: '4rem', textAlign: 'end' }}>
            <RegularText>
              <Link
                removeUnderline
                onClick={() => {
                  setOpenDetail({
                    open: true,
                    id: blockedItem.ip,
                  });
                  dispatch(
                    slice.dashboardSlice.actions.handleBlockDetail({
                      ...blockDetailTable,
                      perPage: '10',
                      page: 1,
                      sort: Enum.SortType.DESC,
                      sortBy: 'accessAt',
                    }),
                  );
                }}
              >
                {blockedItem.totalAccess}
              </Link>
            </RegularText>
          </p>
        ),
        lastSeenOn: <RegularText>{formatDate(blockedItem.lastSeenOn)}</RegularText>,
        ispName:
          dataSettings?.settings.user.plan === Enum.UserPlan.FREE ? (
            '---'
          ) : (
            <RegularText>{blockedItem.provider || '---'}</RegularText>
          ),
        // zipCode: <RegularText>{blockedItem.zipCode || '---'}</RegularText>,
        riskScore: (
          <RegularText>
            {' '}
            {badgeRisk?.tooltip ? (
              dataSettings?.settings.user.plan === Enum.UserPlan.FREE ? (
                '---'
              ) : (
                <Tooltip content={badgeRisk?.tooltip}>
                  <Badge tone={badgeRisk?.tone}>{riskScore === null ? 'N/A' : String(riskScore)}</Badge>
                </Tooltip>
              )
            ) : (
              <Badge tone={badgeRisk?.tone}>{riskScore === null ? 'N/A' : String(riskScore)}</Badge>
            )}
          </RegularText>
        ),
        isVpn: dataSettings?.settings.user.plan === Enum.UserPlan.FREE ? '---'
          : (
            <Badge tone={blockedItem.isVpn === Enum.VPN.TRUE ? 'warning' : 'success'}>{blockedItem.isVpn === Enum.VPN.TRUE ? 'Yes' : 'No'}</Badge>
          ),
      };
    });
  }, [blockDetailTable, data?.listIp, dataSettings?.settings.user.plan, dispatch, handleCountry]);

  const rowMarkup = useMemo(() => {
    return items?.map(({ id, ipBlocked, city, country, lastSeenOn, totalAccess, ispName, riskScore, isVpn }, index) => (
      <IndexTable.Row id={id} key={id} position={index}>
        <IndexTable.Cell>{ipBlocked}</IndexTable.Cell>
        <IndexTable.Cell>{country}</IndexTable.Cell>
        <IndexTable.Cell>{city}</IndexTable.Cell>
        <IndexTable.Cell>{totalAccess}</IndexTable.Cell>
        <IndexTable.Cell>{lastSeenOn}</IndexTable.Cell>
        <IndexTable.Cell>{ispName}</IndexTable.Cell>
        {/* <IndexTable.Cell>{zipCode}</IndexTable.Cell> */}
        <IndexTable.Cell>{riskScore}</IndexTable.Cell>
        <IndexTable.Cell>{isVpn}</IndexTable.Cell>
      </IndexTable.Row>
    ));
  }, [items]);

  return (
    <div className="dashboard-table-container">
      <IndexTable
        resourceName={resourceName}
        itemCount={items?.length || 0}
        emptyState={isFetching ? <SkeletonBodyText lines={20} /> : <EmptyState />}
        headings={[
          { title: <RegularText>IP blocked</RegularText>, id: '1' },
          { title: <RegularText>Country</RegularText>, id: '2' },
          { title: <RegularText>Location</RegularText>, id: '3' },
          { title: <RegularText>Total access</RegularText>, id: '4' },
          {
            title: <RegularText>Last seen on</RegularText>,
            id: '5',
          },
          {
            title: (
              <div className="d-flex">
                <RegularText>Internet provider</RegularText>
                {dataSettings?.settings.user.plan === Enum.UserPlan.FREE ? <TooltipUpdateHigherPlan /> : null}
              </div>
            ),
            id: '6',
          },
          // {
          //   title: <RegularText>Zip code</RegularText>,
          //   id: '7',
          // },
          {
            title: (
              <div className="d-flex va-risk-score">
                <RegularText>Risk score</RegularText>
                {dataSettings?.settings.user.plan === Enum.UserPlan.FREE ? (
                  <TooltipUpdateHigherPlan url="https://docs.ipblocker.io/getting-started/visitor-analytics#risk-score" />
                ) : null}
              </div>
            ),
            id: 'risk-score-header',
          },
          {
            title: (
              <div className="d-flex">
                <RegularText>VPN/Proxy</RegularText>
                {dataSettings?.settings.user.plan === Enum.UserPlan.FREE ? <TooltipUpdateHigherPlan /> : null}
              </div>
            ),
            id: '8',
          },
        ]}
      >
        {isFetching ? <SkeletonBodyText lines={20} /> : rowMarkup}
      </IndexTable>
      <div className="mt-16 mb-16 table-pagination">
        {data && data?.meta.totalItems > 0 ? (
          <Pagination
            label={
              data?.meta.totalItems
                ? `Showing ${(blockHistoryTable.page - 1) * Number(perPage) + 1} to ${Math.min(
                  blockHistoryTable.page * Number(perPage),
                  data?.meta.totalItems,
                )} of ${data?.meta.totalItems} visitors`
                : null
            }
            hasPrevious={data && data?.meta?.currentPage > 1 && !isFetching}
            onPrevious={() => {
              dispatch(
                dashboardSlice.actions.handleBlockHistoryTable({
                  ...blockHistoryTable,
                  page: blockHistoryTable.page - 1,
                }),
              );
            }}
            hasNext={data && data?.meta?.currentPage < Math.ceil((data?.meta?.totalItems ?? 0) / Number(perPage)) && !isFetching}
            onNext={() => {
              dispatch(
                dashboardSlice.actions.handleBlockHistoryTable({
                  ...blockHistoryTable,
                  page: blockHistoryTable.page + 1,
                }),
              );
            }}
          />
        ) : null}
      </div>
      <BlockedHistoryDetail
        open={openDetail.open}
        id={openDetail.id}
        handleClose={() => {
          setOpenDetail({ open: false, id: '' });
        }}
        startDate={Number(allAccessDetail.startDate)}
        endDate={Number(allAccessDetail.endDate)}
      />
    </div>
  );
};

export default memo(TableBlocked);
