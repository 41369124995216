import { memo, useCallback, useState } from 'react';
import { apiCaller } from '@/redux/query';
import { config } from '@/config';
import { useDispatch } from 'react-redux';
import toastSlice from '@/redux/slice/toast.slice';
import { Enum } from '@/constants';
import { checkShowErrorInline, disablePlan, handleToastMutation } from '@/helpers';
import { CardContentProtectionStyled } from '../../styled';
import { IParamsApi } from '@/types/api/params.api';
import useScope from '@/hooks/Scope';
import CustomSettingToggle from '@/components/settingToggle';
import RegularText from '@/components/RegularText';

const ContentProtection = () => {
  const scope = useScope();
  const dispatch = useDispatch();

  const { data, isFetching } = apiCaller.useGetGeneralDetailQuery(config.shop);
  const [activeContentProtection, contentProtectionStatus] = apiCaller.useActiveContentProtectionMutation();
  const [activeBehavior, activeBehaviorStatus] = apiCaller.useActiveBehaviorMutation();

  const [buttonClick, setButtonClick] = useState('');

  const handleProtectText = () => {
    const textProtection: IParamsApi.IContentProtection = {
      protectContents: !data?.settings.protectContents,
    };
    activeContentProtection(textProtection).then((res) => {
      if ('data' in res && res.data.state === 0) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
      } else {
        dispatch(
          toastSlice.actions.handleToast({
            isOpen: true,
            content: 'Updated',
            error: false,
          }),
        );
      }
    });
    setButtonClick('1');
  };

  const disableRightClick = () => {
    const rightClickProtection: IParamsApi.IContentProtection = {
      disableRightClick: !data?.settings.disableRightClick,
    };

    activeBehavior(rightClickProtection).then((res) => {
      if ('data' in res && res.data.state === 0) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
      } else {
        dispatch(
          toastSlice.actions.handleToast({
            isOpen: true,
            content: 'Updated',
            error: false,
          }),
        );
      }
    });
    setButtonClick('2');
  };

  const disableKeyboardShortcut = () => {
    const keywordShotcutProtection: IParamsApi.IContentProtection = {
      disableKeyboardShortcut: !data?.settings.disableKeyboardShortcut,
    };

    activeBehavior(keywordShotcutProtection).then((res) => {
      if ('data' in res && res.data.state === 0) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
      } else {
        dispatch(
          toastSlice.actions.handleToast({
            isOpen: true,
            content: 'Updated',
            error: false,
          }),
        );
      }
    });
    setButtonClick('3');
  };

  const handleDisableInspect = useCallback(() => {
    const disableInspectProtection: IParamsApi.IContentProtection = {
      disableInspect: !data?.settings.disableInspect,
    };

    activeBehavior(disableInspectProtection).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
      }
    });
    setButtonClick('4');
  }, [activeBehavior, data?.settings.disableInspect, dispatch]);

  return data ? (
    <CardContentProtectionStyled>
      <CustomSettingToggle
        title="Protect contents"
        settingToggleProps={{
          enabled: disablePlan([Enum.UserPlan.FREE]) ? false : data.settings.protectContents,
          action: {
            loading: contentProtectionStatus.isLoading || (isFetching && buttonClick === '1'),
            onAction: handleProtectText,
          },
        }}
        minActivePlan={Enum.UserPlan.PREMIUM}
        disableStatus={disablePlan([Enum.UserPlan.FREE]) || scope.isViewOnly}
      >
        <RegularText>Prevent competitors from selecting your content (texts and images).</RegularText>
      </CustomSettingToggle>

      <CustomSettingToggle
        title="Disable right click"
        settingToggleProps={{
          enabled: disablePlan([Enum.UserPlan.FREE, Enum.UserPlan.PREMIUM]) ? false : data.settings.disableRightClick,
          action: {
            loading: (activeBehaviorStatus.isLoading || isFetching) && buttonClick === '2',
            onAction: disableRightClick,
          },
        }}
        minActivePlan={Enum.UserPlan.ENTERPRISE}
        disableStatus={disablePlan([Enum.UserPlan.FREE, Enum.UserPlan.PREMIUM]) || scope.isViewOnly}
      >
        <RegularText>Prevent competitors from copying by disabling right click.</RegularText>
      </CustomSettingToggle>

      <CustomSettingToggle
        title="Disable keyboard shortcut"
        settingToggleProps={{
          enabled: disablePlan([Enum.UserPlan.FREE, Enum.UserPlan.PREMIUM]) ? false : data.settings.disableKeyboardShortcut,
          action: {
            loading: (activeBehaviorStatus.isLoading || isFetching) && buttonClick === '3',
            onAction: disableKeyboardShortcut,
          },
        }}
        minActivePlan={Enum.UserPlan.ENTERPRISE}
        disableStatus={disablePlan([Enum.UserPlan.FREE, Enum.UserPlan.PREMIUM]) || scope.isViewOnly}
      >
        <RegularText>Prevent competitors from copying by disabling keyboard shortcut.</RegularText>
      </CustomSettingToggle>

      <CustomSettingToggle
        title="Deactivate inspect"
        settingToggleProps={{
          enabled: disablePlan([Enum.UserPlan.FREE, Enum.UserPlan.PREMIUM]) ? false : data.settings.disableInspect,
          action: {
            loading: (activeBehaviorStatus.isLoading || isFetching) && buttonClick === '4',
            onAction: handleDisableInspect,
          },
        }}
        minActivePlan={Enum.UserPlan.ENTERPRISE}
        disableStatus={disablePlan([Enum.UserPlan.FREE, Enum.UserPlan.PREMIUM]) || scope.isViewOnly}
      >
        <RegularText>Prevent competitors from inspecting your store.</RegularText>
      </CustomSettingToggle>
    </CardContentProtectionStyled>
  ) : null;
};
export default memo(ContentProtection);
