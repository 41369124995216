import RegularText from '@/components/RegularText';
import AutoCompleteWithTag from '@/components/autoCompleteWithTag';
import { CriteriaType } from '@/constants/enum';
import { validateUrl } from '@/helpers';
import useCountry from '@/hooks/useCountry';
import useErrorRule from '@/pages/block-list/hooks/useErrorRule';
import blockListSlice, { settingSelector } from '@/redux/slice/blockList.slice';
import { Link, TextField } from '@shopify/polaris';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
const ReferrerLink = () => {
  const useError = useErrorRule();
  const dispatch = useDispatch();
  const blockRuleSelected = useSelector(settingSelector);

  const handleCountry = useCountry({
    countryCode: blockRuleSelected.country ? blockRuleSelected.country[0] : undefined,
    stateName: blockRuleSelected.state ? blockRuleSelected.state[0] : undefined,
  });

  const handleChange = (key: keyof typeof blockRuleSelected) => (value: string | string[]) => {
    dispatch(
      blockListSlice.actions.handleSetting({
        ...blockRuleSelected,
        [key]: value,
      }),
    );
  };

  return (
    <div>
      <AutoCompleteWithTag
        requiredIndicator
        options={[...handleCountry.otherCountry, ...handleCountry.listCountry]}
        label="Select country"
        selectedOptions={blockRuleSelected.country || []}
        placeholder="-Country-"
        setSelectedOptions={(value) => {
          if (value.length === 0) {
            useError.handleErrorRule('Country must be added', CriteriaType.Country);
          } else if (useError.getErrorRuleByCriteria(CriteriaType.Country)?.error) {
            useError.removeErrorRule(CriteriaType.Country);
          }
          if (useError.errorSelected?.error && blockRuleSelected.referUrl && !validateUrl(blockRuleSelected.referUrl)) {
            useError.removeErrorRule();
          }
          handleChange('country')(value);
        }}
        allowMultiple={false}
        error={useError.getErrorRuleByCriteria(CriteriaType.Country)?.error}
        onBlur={() => {
          if (blockRuleSelected.country?.length === 0) {
            useError.handleErrorRule('Country must be added', CriteriaType.Country);
          }
        }}
      />
      <div className="mt-8" />
      <TextField
        autoComplete="off"
        requiredIndicator
        label="Enter referrer URL"
        prefix="https://"
        value={blockRuleSelected.referUrl}
        helpText={
          <RegularText>
            <Link url="https://docs.ipblocker.io/faq/what-is-a-referrer-url" target="_blank">
              Read more
            </Link>{' '}
            about referrer URL.
          </RegularText>
        }
        onChange={(value) => {
          if (useError.errorSelected?.error) {
            useError.removeErrorRule();
          }
          handleChange('referUrl')(value);
        }}
        error={useError.errorSelected?.error}
        onBlur={() => {
          if (!blockRuleSelected.referUrl) {
            useError.handleErrorRule('Referrer link must be added');
          } else if (!validateUrl(blockRuleSelected.referUrl)) {
            useError.handleErrorRule('Referrer link invalid');
          }
        }}
      />
      <div className="mt-8" />

      <TextField
        autoComplete="off"
        label="Ref URL name (optional)"
        value={blockRuleSelected.shortReferUrl}
        onChange={handleChange('shortReferUrl')}
      />
    </div>
  );
};

export default memo(ReferrerLink);
