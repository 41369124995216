import { colors } from '@/constants';
import styled from 'styled-components';

export const WelcomeStyled = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px;
  .Polaris-Box {
    max-width: 950px;
    width: 950px;
  }
  .welcome-container {
    ul {
      padding: 0;
      display: flex;
      justify-content: center;
      position: relative;
      margin-bottom: 35px;
      .welcome-bridge {
        position: absolute;
        top: 20px;
        left: 50px;
        width: 810px;
        background-color: #ebebeb;
        height: 10px;
        z-index: 1;
      }
      li {
        width: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .step-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
        }
        .step-number {
          text-align: center;
          line-height: 50px;
          width: 50px;
          height: 50px;
          color: #525252;
          border-radius: 25px;
          background-color: #ebebeb;
          z-index: 2;
          font-weight: 700;
          user-select: none;
          transition: all 0.3s ease-in-out;
        }
        .Polaris-Text--root {
          text-align: center;
          margin-top: 20px;
          color: #ababab;
        }
        .Polaris-Text--root:hover {
          text-decoration: underline;
        }
      }
      .active {
        .step-number {
          line-height: 40px;
          border: 5px solid #ebebeb;
          background-color: #fff;
          color: ${colors.primary};
          z-index: 2;
        }
        .Polaris-Text--root {
          color: #303641;
        }
      }
      .completed {
        .step-number {
          line-height: 40px;
          background-color: ${colors.primary};
          border: 5px solid ${colors.primary};
          color: white;
          z-index: 2;
        }
        .Polaris-Text--root {
          color: ${colors.primary};
        }
      }
    }
  }
  .step-two {
    img {
      margin-top: 1rem;
      width: 910px;
    }
  }

  .Polaris-Text--root {
    text-align: center;
  }

  .mt-20 {
    margin-top: 20px;
  }
  .button-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .image-center {
    display: flex;
    justify-content: center;
    margin: 16px auto;
  }
  .step-three-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
  }
`;
