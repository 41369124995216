import { Text } from '@shopify/polaris';
import { memo } from 'react';
const BoldText = ({ children }: { children: React.ReactNode }) => {
  return (
    <Text as="h6" variant="headingMd">
      {children}
    </Text>
  );
};
export default memo(BoldText);
