import useScope from '@/hooks/Scope';
import { Button, ButtonProps } from '@shopify/polaris';
import { memo } from 'react';

const CustomButton = (props: ButtonProps) => {
  const scope = useScope();
  return (
    <Button {...props} disabled={scope.isViewOnly}>
      {props.children}
    </Button>
  );
};
export default memo(CustomButton);
