import { uniq } from '@/helpers';
import useLimitRule from '@/pages/block-list/hooks';
import useErrorRule from '@/pages/block-list/hooks/useErrorRule';
import { apiCaller } from '@/redux/query';
import blockListSlice, { settingSelector } from '@/redux/slice/blockList.slice';
import { InlineStack, TextField } from '@shopify/polaris';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface Props {
  label: string;
  error?: string;
  placeholder?: string;
}

const IpAddress = ({ label, placeholder }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, { data }] = apiCaller.useUpsertRuleMutation();
  const limitRule = useLimitRule();
  const useError = useErrorRule();
  const dispatch = useDispatch();
  const blockRuleSelected = useSelector(settingSelector);
  const handleChange = (ipAddress: string) => {
    if (useError.errorSelected?.error) {
      useError.removeErrorRule();
    }
    dispatch(
      blockListSlice.actions.handleSetting({
        ...blockRuleSelected,
        ipAddress,
      }),
    );
  };

  const onRemove = (value: string) => {
    if (blockRuleSelected.ipAddress) {
      useError.removeErrorRule();
    } else {
      useError.handleErrorRule('IP address is required');
    }
    const listIp = blockRuleSelected.ipAddress ? [...blockRuleSelected.ipAddress?.split(',')] : [];
    const newListip = listIp.filter((ip) => {
      return ip.trim() !== value;
    });
    dispatch(
      blockListSlice.actions.handleSetting({
        ...blockRuleSelected,
        ipAddress: newListip.join(','),
      }),
    );
  };
  return (
    <div className="block-ip-address">
      <TextField
        disabled={
          limitRule.count < 1 ||
          (!!blockRuleSelected.ipAddress && limitRule.count < [...blockRuleSelected.ipAddress?.split(',')].length) ||
          (!!blockRuleSelected.ipAddress && [...blockRuleSelected.ipAddress?.split(',')].length > 1 && !!blockRuleSelected.id)
        }
        requiredIndicator
        autoComplete="off"
        label={label}
        error={useError.errorSelected?.error}
        onBlur={() => {
          if (blockRuleSelected.id && blockRuleSelected.ipAddress?.includes(',')) {
            useError.handleErrorRule("You can't edit multiple IP address");
          }
          if (!blockRuleSelected.ipAddress) {
            useError.handleErrorRule('IP address is required');
          }
        }}
        value={blockRuleSelected.ipAddress}
        placeholder={placeholder}
        onChange={handleChange}
        helpText={'You can enter multiple IPs at the same time, each IP separated by a comma.'}
      />
      <InlineStack gap={'100'} wrap={true}>
        {uniq(blockRuleSelected.ipAddress?.split(',').map((item) => item.trim()) || [])
          .filter((item) => item !== '')
          .splice(0, 500)
          .map((ip) => {
            return (
              <div className={'mt-8 mr-8 Polaris-Tag'} key={ip}>
                <h1 title={ip} className={'Polaris-Tag__TagText'}>
                  {ip}
                </h1>
                <button className="Polaris-Tag__Button" onClick={() => onRemove(ip)}>
                  <div>X</div>
                </button>
              </div>
            );
          })}
      </InlineStack>
    </div>
  );
};

export default memo(IpAddress);
