/* eslint-disable react-hooks/exhaustive-deps */
import RegularText from '@/components/RegularText';
import CustomDatePicker from '@/components/datePicker/DatePicker';
import CustomLayout from '@/components/layout';
import { config } from '@/config';
import { Enum } from '@/constants';
import { openCrisp } from '@/helpers';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { bannerSupportAnalyticsSelector, limitAccessBannerSelector } from '@/redux/slice/banner.slice';
import { blockedDetailSelector, blockHistorySelector } from '@/redux/slice/dashboard.slice';
import visitorLogSlice, { allAccessSelector, titleBtnDatePickerSelector, visitorDetailSelector } from '@/redux/slice/visitorAnalytics.slice';
import { Banner, LegacyStack, Loading } from '@shopify/polaris';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Analytics from './components/Analytics';
import AnalyticsChart from './components/Chart';
import HeatMap from './components/heatMap';
import Table from './components/table';
import { DashboardStyled } from './styled';
import mixpanel from 'mixpanel-browser';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';

const SecondaryAction = (): JSX.Element => {
  const accessAll = useSelector(allAccessSelector);
  const dataSettings = useSelector(dataSettingsSelector);
  const blockHistoryTable = useSelector(blockHistorySelector);
  const blockedDetailTable = useSelector(blockedDetailSelector);
  const visitorsDetailTable = useSelector(visitorDetailSelector);
  const titleBtn = useSelector(titleBtnDatePickerSelector);
  const dispatch = useDispatch();
  const onSaveDatePicker = (startDate: Date, endDate: Date) => {
    dispatch(
      visitorLogSlice.actions.handleAllAccessTable({
        ...blockHistoryTable,
        startDate,
        endDate,
      }),
    );
    // Blocked
    dispatch(slice.dashboardSlice.actions.handleBlockDetail({ ...blockedDetailTable, startDate, endDate }));
    // Allow
    dispatch(slice.visitorAnalyticsSlice.actions.handleAllAccessDetail({ ...visitorsDetailTable, startDate, endDate }));
  };
  const onSaveTitleBtnDatePicker = (title: string) => {
    dispatch(visitorLogSlice.actions.handleTitleBtnDatePicker(title));
  };
  return (
    <LegacyStack>
      <CustomDatePicker
        titleButton={titleBtn}
        setTitleButton={onSaveTitleBtnDatePicker}
        startDate={accessAll.startDate || 0}
        endDate={accessAll.endDate || 0}
        onSave={onSaveDatePicker}
        disabled={dataSettings?.settings.user.plan === Enum.UserPlan.FREE}
      />
    </LegacyStack>
  );
};

const Dashboard = () => {
  const bannerSupportAnalytics = useSelector(bannerSupportAnalyticsSelector);
  const dispatch = useDispatch();
  const account = apiCaller.useGetGeneralDetailQuery(config?.shop);
  const limitAccessBanner = useSelector(limitAccessBannerSelector);

  return (
    <CustomLayout
      layoutProps={{
        title: 'Visitor analytics',
        secondaryActions: <SecondaryAction />,
        fullWidth: true,
      }}
      isVisibleHeader={!account.data?.settings.isPublishSite ? false : true}
    >
      {account?.isLoading ? (
        <Loading />
      ) : (
        <DashboardStyled>
          {account && account?.data?.settings?.user.plan === Enum.UserPlan.FREE && limitAccessBanner ? (
            <div className="mb-16">
              <Banner
                title="Unlock the full potential of Blockify"
                tone="info"
                onDismiss={() => {
                  dispatch(slice.banner.actions.handleShowLimitAccessBanner(false));
                }}
                action={{
                  content: 'Upgrade now',
                  onAction: () => {
                    mixpanel.track("W_unlock_full_potential_banner")
                    window.open(
                      `https://www.wix.com/apps/upgrade/${process.env.REACT_APP_APP_ID}?appInstanceId=${account?.data?.settings.instanceId}`
                    )
                  }
                }}
              >
                Our free plan displays and analytics the <b>first 200 visits</b> to your website. <b>Upgrade now</b> to unlock the full list of your website's accesses.
              </Banner>
            </div>
          ) : null}

          {bannerSupportAnalytics ? (
            <div className="mb-16">
              <Banner
                title="Analytics apps still record blocked visitors"
                tone="info"
                onDismiss={() => dispatch(slice.banner.actions.handleShowBannerSupportVisitorAnalytics(false))}
                action={{
                  content: 'Contact support now',
                  onAction: openCrisp,
                }}
              >
                <RegularText>
                  Due to the visits logging mechanism of Google Analytics, Wix Analytics, and other analytics apps, all visiting attempts will still be recorded in the analytic report. It's important to note that these logs are just visiting attempts that were blocked immediately by Blockify. If you have any further questions, please do not hesitate to drop us a message.
                </RegularText>
              </Banner>
            </div>
          ) : null}

          <Analytics />
          <div className="mt-16">
            <AnalyticsChart />
          </div>
          <div className="mt-16">
            <HeatMap />
          </div>
          <div className="mt-16">
            <Table />
          </div>
        </DashboardStyled>
      )}
    </CustomLayout>
  );
};
export default memo(Dashboard);
