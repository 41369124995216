import { images } from '@/asset';
import CriticalBanner from '@/components/CriticalBanner';
import RegularText from '@/components/RegularText';
import SkeletonPage from '@/components/SkeletonPage';
import { Enum } from '@/constants';
import { ListType } from '@/constants/enum';
import { deepObjectEqual } from '@/helpers';
import { apiCaller } from '@/redux/query';
import toastSlice from '@/redux/slice/toast.slice';
import { Badge, Button, ButtonGroup, LegacyCard, Text } from '@shopify/polaris';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReactSortable } from 'react-sortablejs';
import { StyledSortable } from './styled';

interface IItemState {
  id: string;
  criteriaType: string;
  description: string;
  src: string;
  commonType: string;
}

const ruleInfo = [
  {
    criteriaType: Enum.CriteriaType.IpAddress,
    commonType: ListType.WhiteList,
    description: 'Whitelist - IP address',
    src: images.block_ip_address,
  },
  {
    criteriaType: Enum.CriteriaType.IpAddressStartWith,
    description: 'Whitelist - IP start with',
    src: images.block_ip_range,
    commonType: ListType.WhiteList,
  },
  {
    criteriaType: Enum.CriteriaType.IpRanges,
    description: 'Whitelist - IP ranges',
    src: images.block_ip_ranges,
    commonType: ListType.WhiteList,
  },
  {
    criteriaType: Enum.CriteriaType.Country,
    description: 'Whitelist - Country',
    src: images.block_country,
    commonType: ListType.WhiteList,
  },
  {
    criteriaType: Enum.CriteriaType.Province,
    description: 'Whitelist - State',
    src: images.block_state,
    commonType: ListType.WhiteList,
  },
  {
    criteriaType: Enum.CriteriaType.ISP,
    description: 'Whitelist - ISP',
    src: images.block_isp,
    commonType: ListType.WhiteList,
  },
  {
    criteriaType: Enum.CriteriaType.ReferralLink,
    description: 'Whitelist - Referrer URL',
    src: images.referralLink,
    commonType: ListType.WhiteList,
  },
  {
    criteriaType: Enum.CriteriaType.UserAgent,
    description: 'Whitelist - User agent',
    src: images.userAgent,
    commonType: ListType.WhiteList,
  },
  {
    criteriaType: Enum.CriteriaType.SpecificPage,
    description: 'Whitelist - Specific URL',
    src: images.block_specific_page,
    commonType: ListType.WhiteList,
  },
  {
    criteriaType: Enum.CriteriaType.IpAddress,
    description: 'Blacklist - IP address',
    src: images.block_ip_address,
    commonType: ListType.BlackList,
  },
  {
    criteriaType: Enum.CriteriaType.IpAddressStartWith,
    description: 'Blacklist - IP start with',
    src: images.block_ip_range,
    commonType: ListType.BlackList,
  },
  {
    criteriaType: Enum.CriteriaType.IpRanges,
    description: 'Blacklist - IP ranges',
    src: images.block_ip_ranges,
    commonType: ListType.BlackList,
  },
  {
    criteriaType: Enum.CriteriaType.Country,
    description: 'Blacklist - Country',
    src: images.block_country,
    commonType: ListType.BlackList,
  },
  {
    criteriaType: Enum.CriteriaType.Province,
    description: 'Blacklist - State',
    src: images.block_state,
    commonType: ListType.BlackList,
  },
  {
    criteriaType: Enum.CriteriaType.ISP,
    description: 'Blacklist - ISP',
    src: images.block_isp,
    commonType: ListType.BlackList,
  },
  {
    criteriaType: Enum.CriteriaType.ReferralLink,
    description: 'Blacklist - Referrer URL',
    src: images.referralLink,
    commonType: ListType.BlackList,
  },
  {
    criteriaType: Enum.CriteriaType.UserAgent,
    description: 'Blacklist - User agent',
    src: images.userAgent,
    commonType: ListType.BlackList,
  },
  {
    criteriaType: Enum.CriteriaType.SpecificPage,
    description: 'Blacklist - Specific URL',
    src: images.block_specific_page,
    commonType: ListType.BlackList,
  },
];

const ConfigRule: FC = () => {
  const [trigger, { isFetching }] = apiCaller.useLazyGetConfigPriorityQuery();
  const [updateConfig, { isLoading }] = apiCaller.useUpdateConfigPriorityMutation();
  const dispatch = useDispatch();
  const [state, setState] = useState<{
    currentData: Array<IItemState>;
    backupData: Array<IItemState>;
    isVisible: boolean;
    msg: string;
  }>({
    currentData: [],
    backupData: [],
    isVisible: false,
    msg: '',
  });

  useEffect(() => {
    const getData = async () => {
      const { data } = await trigger();
      const initialState = data?.priorityConfig.map((item) => {
        const rule = ruleInfo.find((rule) => rule.criteriaType === item.criteriaType && rule.commonType === item.commonType);
        return {
          ...item,
          id: item.criteriaType + ' ' + item.commonType,
          src: rule?.src || '',
          description: rule?.description || item.description,
        };
      });

      setState({
        ...state,
        currentData: initialState || [],
        backupData: initialState || [],
      });
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const colorBlacklist = '#ebebeb',
    colorWhitelist = '#fff';

  const handleUpdateRules = () => {
    const params = state.currentData.map((item) => ({ criteriaType: item.criteriaType, commonType: item.commonType }));
    updateConfig({ priorityConfig: params }).then((res) => {
      if ('data' in res && res.data.state === 0) {
        setState({
          ...state,
          isVisible: true,
          msg: res.data.msg,
        });
      } else {
        setState({
          ...state,
          backupData: state.currentData || [],
          isVisible: false,
          msg: '',
        });
        dispatch(
          toastSlice.actions.handleToast({
            isOpen: true,
            content: 'Updated',
            error: false,
          }),
        );
      }
    });
  };
  const handleLearnMorePriorityRules = () => {
    window.open('https://docs.ipblocker.io/getting-started/priority-rule-management');
  };

  return (
    <StyledSortable>
      <LegacyCard>
        <LegacyCard.Section>
          <div className="sort-header">
            <div className="d-flex ">
              <Text as="h5" variant="headingSm">
                Priority Rule Management
              </Text>
              <span className="ml-8">
                <Badge progress="complete" tone={deepObjectEqual(state.currentData, state.backupData) ? 'success' : 'attention'}>
                  {deepObjectEqual(state.currentData, state.backupData) ? 'Saved' : 'Edited'}
                </Badge>
              </span>
            </div>
            <ButtonGroup>
              <Button
                disabled={deepObjectEqual(state.currentData, state.backupData)}
                onClick={() => {
                  setState({ ...state, currentData: state.backupData });
                }}
              >
                Refresh
              </Button>
              <Button
                loading={isLoading}
                disabled={deepObjectEqual(state.currentData, state.backupData)}
                variant="primary"
                onClick={handleUpdateRules}
              >
                Save
              </Button>
            </ButtonGroup>
          </div>

          <div className="">
            <RegularText>
              {'Our system will prioritize checking conditions according to the list below. '}
              <Button onClick={handleLearnMorePriorityRules} variant="plain">
                Learn more
              </Button>
            </RegularText>
          </div>

          <div className="mt-16">
            <CriticalBanner isVisible={state.isVisible}>{state.msg}</CriticalBanner>
          </div>

          <div className="mt-16 mb-8">
            <RegularText>Rule list</RegularText>
          </div>
          {isFetching ? (
            <SkeletonPage />
          ) : (
            <ReactSortable
              list={state.currentData}
              setList={(list) => {
                setState({ ...state, currentData: list });
              }}
            >
              {state.currentData.map((item, index) => (
                <div
                  className="sort-item"
                  key={item.id}
                  style={{
                    backgroundColor: item.commonType === ListType.BlackList ? colorBlacklist : colorWhitelist,
                    color: item.commonType !== ListType.BlackList ? '#000' : '#000',
                    border: `1px solid ${item.commonType === ListType.BlackList ? '#fff' : 'rgb(235, 235, 235)'}`,
                  }}
                >
                  <Text as="p" variant="headingMd">
                    {index + 1}
                  </Text>

                  <img
                    style={{
                      border: `1px ${item.commonType === ListType.BlackList ? 'rgb(235, 235, 235)' : colorBlacklist} solid `,
                    }}
                    className="config-rule-img ml-16"
                    src={item.src}
                    alt={item.description}
                  />

                  <div className="ml-16">
                    <RegularText>{item.description}</RegularText>
                  </div>
                </div>
              ))}
            </ReactSortable>
          )}
        </LegacyCard.Section>
      </LegacyCard>
    </StyledSortable>
  );
};

export default ConfigRule;
