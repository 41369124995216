import EmptyState from '@/components/EmptyState';
import RegularText from '@/components/RegularText';
import { Enum } from '@/constants';
import { checkConditionErrorInline, dateToTimeStamp, formatDate } from '@/helpers';
import useCountry from '@/hooks/useCountry';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import visitorLogSlice, {
  allAccessDetailSelector,
  allAccessSelector,
  perPageSelector,
  visitorDetailSelector,
  visitorLogSelector,
} from '@/redux/slice/visitorAnalytics.slice';
import {
  Badge,
  BadgeProps,
  Icon,
  IndexTable,
  Modal,
  Pagination,
  SkeletonBodyText,
  Text,
  Tooltip,
  useIndexResourceState,
} from '@shopify/polaris';
import { CircleDisableMinor } from '@shopify/polaris-icons';
import { memo, useCallback, useMemo, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useDispatch, useSelector } from 'react-redux';
import VisitorsDetail from '../Detail/Visitors';
import TooltipUpdateHigherPlan from './TooltipUpdateHigherPlan';

const badgeRiskConfig: Array<{ score: Array<number>; tone: BadgeProps['tone']; tooltip: string }> = [
  {
    score: [-1, Enum.RiskLevel.Safe],
    tone: 'success',
    tooltip: 'Low risk IP',
  },
  {
    score: [Enum.RiskLevel.Safe, Enum.RiskLevel.Risky],
    tone: 'warning',
    tooltip: 'High risk IP',
  },
  {
    score: [Enum.RiskLevel.Risky, Enum.RiskLevel.Dangerous],
    tone: 'critical',
    tooltip: 'Dangerous IP',
  },
];

const TableVisitorLog = (): JSX.Element => {
  const dispatch = useDispatch();
  const [confirmBlock, setConfirmBlock] = useState({
    status: false,
    ip: [''],
  });
  const perPage = useSelector(perPageSelector);
  const dataSettings = useSelector(dataSettingsSelector);
  const allAccess = useSelector(allAccessSelector);
  const allAccessDetail = useSelector(allAccessDetailSelector);
  const visitorLogTable = useSelector(visitorLogSelector);
  const visitorDetail = useSelector(visitorDetailSelector);
  const [openDetail, setOpenDetail] = useState({
    open: false,
    id: '',
  });
  const handleCountry = useCountry();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [addBlackList, { isLoading }] = apiCaller.useUpsertRuleMutation();
  const { data, isFetching } = apiCaller.useFetchVisitorListQuery({
    ...visitorLogTable,
    sort: visitorLogTable.sort.toUpperCase(),
    endDate: dateToTimeStamp(allAccess.endDate),
    startDate: dateToTimeStamp(allAccess.startDate),
    perPage,
  });
  const resourceName = {
    singular: 'visitor',
    plural: 'visitors',
  };

  const items = useMemo(() => {
    return data?.listIp.map((visitorLogItem) => {
      const riskScore = visitorLogItem.risk;

      let badgeRisk;
      if (riskScore === null) {
        badgeRisk = undefined;
      } else {
        badgeRisk = badgeRiskConfig.find((item) => {
          return item.score[0] < riskScore && riskScore <= item.score[1];
        });
      }

      return {
        id: visitorLogItem.ip,
        ipBlocked: (
          <RegularText>
            {visitorLogItem.ip}
            <br />
            <Text variant="bodySm" as="span">
              {visitorLogItem.ipv4 ? `IPv4: ${visitorLogItem.ipv4}` : null}
            </Text>
          </RegularText>
        ),
        country: (
          <Tooltip dismissOnMouseOut content={handleCountry.renderCountry(visitorLogItem.countryCode)}>
            <ReactCountryFlag svg className="emojiFlag" countryCode={visitorLogItem.countryCode} />
          </Tooltip>
        ),
        city: (
          <RegularText>{[visitorLogItem.regionName, visitorLogItem.cityName].filter((item) => !!item).join(' - ')}</RegularText>
        ),
        totalAccess: (
          <p style={{ width: '4rem', textAlign: 'end' }}>
            <RegularText>
              <div
                className="text-color"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenDetail({
                    open: true,
                    id: visitorLogItem.ip,
                  });
                  dispatch(
                    slice.visitorAnalyticsSlice.actions.handleVisitorLogModalTable({
                      ...visitorDetail,
                      perPage: '10',
                      page: 1,
                      sort: Enum.SortType.DESC,
                      sortBy: 'accessAt',
                    }),
                  );
                }}
              >
                {visitorLogItem.totalAccess}
              </div>
            </RegularText>
          </p>
        ),
        lastSeenOn: <RegularText>{formatDate(visitorLogItem.lastSeenOn)}</RegularText>,
        ispName:
          dataSettings?.settings.user.plan === Enum.UserPlan.FREE ? (
            '---'
          ) : (
            <RegularText>{visitorLogItem.provider || '---'}</RegularText>
          ),
        // zipCode: <RegularText>{visitorLogItem.zipCode || '---'}</RegularText>,
        risk: badgeRisk?.tooltip ? (
          dataSettings?.settings.user.plan === Enum.UserPlan.FREE ? (
            '---'
          ) : (
            <Tooltip content={badgeRisk?.tooltip}>
              <Badge tone={badgeRisk?.tone}>{riskScore === null ? 'N/A' : String(riskScore)}</Badge>
            </Tooltip>
          )
        ) : (
          <Badge tone={badgeRisk?.tone}>{riskScore === null ? 'N/A' : String(riskScore)}</Badge>
        ),
        isVpn:
          dataSettings?.settings.user.plan === Enum.UserPlan.FREE ? (
            '---'
          ) : (
            <Badge tone={visitorLogItem.isVpn === Enum.VPN.TRUE ? 'warning' : 'success'}>
              {visitorLogItem.isVpn === Enum.VPN.TRUE ? 'Yes' : 'No'}
            </Badge>
          ),
        status:
          visitorLogItem.status === 'allow' ? (
            <Badge tone="success">{visitorLogItem.status.charAt(0).toUpperCase() + visitorLogItem.status.slice(1)}</Badge>
          ) : (
            <Badge tone="critical">{visitorLogItem.status.charAt(0).toUpperCase() + visitorLogItem.status.slice(1)}</Badge>
          ),
        action:
          visitorLogItem.status === 'block' ? (
            <Tooltip dismissOnMouseOut content="Blocked">
              <div className="btn-disable">
                <Icon tone="subdued" source={CircleDisableMinor} />
              </div>
            </Tooltip>
          ) : (
            <Tooltip dismissOnMouseOut content="Add to blocked list">
              <div
                className="btn-disable pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  setConfirmBlock({
                    status: true,
                    ip: [visitorLogItem.ip],
                  });
                }}
              >
                <Icon tone="critical" source={CircleDisableMinor} />
              </div>
            </Tooltip>
          ),
      };
    });
  }, [data?.listIp, dataSettings?.settings.user.plan, dispatch, handleCountry, visitorDetail]);

  const { selectedResources, allResourcesSelected, handleSelectionChange, clearSelection } = useIndexResourceState(items || []);

  const rowMarkup = useMemo(() => {
    return items?.map(
      ({ id, ipBlocked, action, city, country, lastSeenOn, risk, status, totalAccess, ispName, isVpn }, index) => (
        <IndexTable.Row id={id} key={id} position={index} selected={selectedResources.includes(id)}>
          <IndexTable.Cell>{ipBlocked}</IndexTable.Cell>
          <IndexTable.Cell>{country}</IndexTable.Cell>
          <IndexTable.Cell>{city}</IndexTable.Cell>
          <IndexTable.Cell>{totalAccess}</IndexTable.Cell>
          <IndexTable.Cell>{lastSeenOn}</IndexTable.Cell>
          <IndexTable.Cell>{ispName}</IndexTable.Cell>
          {/* <IndexTable.Cell>{zipCode}</IndexTable.Cell> */}
          <IndexTable.Cell id="risk-score">
            <div className="w-57">{risk}</div>
          </IndexTable.Cell>
          <IndexTable.Cell>{isVpn}</IndexTable.Cell>
          <IndexTable.Cell>{status}</IndexTable.Cell>
          <IndexTable.Cell>{action}</IndexTable.Cell>
        </IndexTable.Row>
      ),
    );
  }, [items, selectedResources]);

  const handleOpenModalBlock = useCallback(
    () => () => {
      setConfirmBlock({ ip: selectedResources || [], status: true });
    },
    [selectedResources],
  );

  const promotedBulkActions = [
    {
      content: 'Cancel',
      onAction: () => clearSelection(),
    },
    {
      content: 'Add IP to blacklist',
      onAction: handleOpenModalBlock(),
    },
  ];

  return (
    <div
      className={dataSettings?.settings.user.plan === Enum.UserPlan.FREE ? 'dashboard-table-container' : 'allow-table-container'}
    >
      <IndexTable
        resourceName={resourceName}
        itemCount={items?.length || 0}
        promotedBulkActions={promotedBulkActions}
        onSelectionChange={dataSettings?.settings.user.plan !== Enum.UserPlan.FREE ? handleSelectionChange : () => {}}
        selectedItemsCount={
          dataSettings?.settings.user.plan !== Enum.UserPlan.FREE && allResourcesSelected ? 'All' : selectedResources.length
        }
        emptyState={isFetching ? <SkeletonBodyText lines={20} /> : <EmptyState />}
        headings={[
          { title: <RegularText>IP address</RegularText>, id: '1' },
          { title: <RegularText>Country</RegularText>, id: '2' },
          { title: <RegularText>Location</RegularText>, id: '3' },
          { title: <RegularText>Total access</RegularText>, id: '4' },
          {
            title: <RegularText>Last seen on</RegularText>,
            id: '5',
          },
          {
            title: (
              <div className="d-flex">
                <RegularText>Internet provider</RegularText>
                {dataSettings?.settings.user.plan === Enum.UserPlan.FREE ? <TooltipUpdateHigherPlan /> : null}
              </div>
            ),
            id: '6',
          },
          // {
          //   title: <RegularText>Zip code</RegularText>,
          //   id: '7',
          // },
          {
            title: (
              <div className="d-flex va-risk-score">
                <RegularText>Risk score</RegularText>
                {dataSettings?.settings.user.plan === Enum.UserPlan.FREE ? (
                  <TooltipUpdateHigherPlan url="https://docs.ipblocker.io/getting-started/visitor-analytics#risk-score" />
                ) : null}
              </div>
            ),
            id: 'risk-score-header',
          },
          {
            title: (
              <div className="d-flex">
                <RegularText>VPN/Proxy</RegularText>
                {dataSettings?.settings.user.plan === Enum.UserPlan.FREE ? <TooltipUpdateHigherPlan /> : null}
              </div>
            ),
            id: '8',
          },
          { title: <RegularText>Status</RegularText>, id: '9' },
          { title: <RegularText>Action</RegularText>, id: '10' },
        ]}
      >
        {isFetching ? <SkeletonBodyText lines={20} /> : rowMarkup}
      </IndexTable>
      <div className="mt-16 mb-16 table-pagination">
        {data && data?.meta.totalItems > 0 ? (
          <Pagination
            label={
              data?.meta.totalItems
                ? `Showing ${(visitorLogTable.page - 1) * Number(perPage) + 1} to ${Math.min(
                    visitorLogTable.page * Number(perPage),
                    data?.meta.totalItems,
                  )} of ${data?.meta.totalItems} visitors`
                : null
            }
            hasPrevious={data && data?.meta?.currentPage > 1 && !isFetching}
            onPrevious={() => {
              dispatch(
                visitorLogSlice.actions.handleVisitorLogTable({
                  ...visitorLogTable,
                  page: visitorLogTable.page - 1,
                }),
              );
            }}
            hasNext={data && data?.meta?.currentPage < Math.ceil((data?.meta?.totalItems ?? 0) / Number(perPage)) && !isFetching}
            onNext={() => {
              dispatch(
                visitorLogSlice.actions.handleVisitorLogTable({
                  ...visitorLogTable,
                  page: visitorLogTable.page + 1,
                }),
              );
            }}
          />
        ) : null}
      </div>
      <VisitorsDetail
        open={openDetail.open}
        id={openDetail.id}
        handleClose={() => {
          setOpenDetail({ open: false, id: '' });
        }}
        startDate={Number(allAccessDetail.startDate)}
        endDate={Number(allAccessDetail.endDate)}
      />
      <Modal
        onClose={() => setConfirmBlock({ ip: [''], status: false })}
        open={confirmBlock.status}
        title="Block IP?"
        sectioned
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: () => setConfirmBlock({ ip: [''], status: false }),
          },
        ]}
        primaryAction={{
          loading: isLoading,
          content: 'Block',
          onAction: () => {
            addBlackList({
              type: Enum.ActionType.Block,
              criteria: Enum.CriteriaType.IpAddress,
              ipAddress: confirmBlock.ip,
              priority: Enum.ListType.BlackList,
              isActive: true,
            }).then((res) => {
              const condition = checkConditionErrorInline(res);
              dispatch(
                slice.toastSlice.actions.handleToast({
                  content: condition.msg,
                  isOpen: true,
                  error: false,
                }),
              );
              setConfirmBlock({ ip: [''], status: false });
            });
            clearSelection();
          },
        }}
      >
        <RegularText>This IP will be added to the blacklist for blocking. You can remove it anytime.</RegularText>
      </Modal>
    </div>
  );
};

export default memo(TableVisitorLog);
