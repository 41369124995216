import { getOptionsSelectedAdd, getOptionsSelectedRemove } from '@/helpers';
import { Autocomplete, LegacyStack, Tag } from '@shopify/polaris';
import { memo, useEffect, useState } from 'react';
import { IAutoCompletePagenate, IOptions } from '@/types/components';

function AutoCompleteWithPaginate({ ...props }: IAutoCompletePagenate) {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<Array<IOptions>>([]);

  useEffect(() => {
    setOptions(props.options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(props.options)]);

  useEffect(() => {
    if (!props.allowMultiple) {
      setInputValue(props.tag[0]?.label || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.allowMultiple, JSON.stringify(props.tag)]);

  const updateText = (value: string) => {
    if (props.handleSearch) {
      setInputValue(value);
      props.handleSearch(value);
    } else {
      setInputValue(value);
      const resultOptions = props.options.filter((option) => option.label.toLocaleLowerCase().includes(value.toLowerCase()));
      setOptions(resultOptions);
    }
  };
  const handleReturnValue = (selected: string[]) => {
    const isAdd = selected.length > props.selectedOptions.length;

    const result = !props.allowMultiple
      ? [props.options.find((item) => item.value === selected[0]) || { value: '', label: '' }]
      : isAdd
      ? getOptionsSelectedAdd(selected, props.tag, props.options)
      : getOptionsSelectedRemove(selected, props.tag);
    return result;
  };

  const removeTag = (tag: string) => () => {
    const newTags = props.selectedOptions.filter((item) => item !== tag);
    props.setSelectedOptions(handleReturnValue(newTags));
  };

  const handleOnSelect = (value: Array<string>) => {
    props.setSelectedOptions(handleReturnValue(value));
  };

  const textField = (
    <Autocomplete.TextField
      error={props.error}
      onChange={updateText}
      label={props.label}
      value={inputValue}
      autoComplete="off"
      requiredIndicator={props.requiredIndicator}
      placeholder={props.placeholder}
      disabled={props.disable}
      helpText={props.helpText}
      onBlur={props.onBlur}
    />
  );

  const tagsMarkup =
    props.tag.length > 0
      ? props.tag.map((tag) => {
          return (
            <Tag key={`option${tag.value}`} onRemove={removeTag(tag.value)}>
              {tag.label || ''}
            </Tag>
          );
        })
      : null;

  const selectedTagMarkup =
    props.selectedOptions.length > 0 && props.allowMultiple ? <LegacyStack spacing="extraTight">{tagsMarkup}</LegacyStack> : null;

  return (
    <div>
      <Autocomplete
        allowMultiple={props.allowMultiple}
        options={options}
        selected={props.selectedOptions}
        textField={textField}
        onSelect={handleOnSelect}
      />
      <div style={{ marginTop: '8px' }}>{selectedTagMarkup}</div>
    </div>
  );
}

export default memo(AutoCompleteWithPaginate);
