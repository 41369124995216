import { IResponseApi } from '@/types/api/response.api';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface IState extends IResponseApi.IGeneralDetail {}

const initialState: { data: IState | null; loading: boolean } = {
  data: null,
  loading: false,
};
export const dataSettingsSlice = createSlice({
  name: 'dataSettings',
  initialState,
  reducers: {
    handleSettings: (state, action: PayloadAction<IState>) => {
      state.data = action.payload;
    },
    handleLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const dataSettingsSelector = createSelector(
  (state: RootState) => state.dataSettings,
  (state) => state.data,
);
export const loadingSelector = createSelector(
  (state: RootState) => state.dataSettings,
  (state) => state.loading,
);

export default dataSettingsSlice;
